import React, { useState, useRef, useEffect } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';  // Import Snow theme
import 'quill/dist/quill.bubble.css'; // Import Bubble theme
import PocketBase from 'pocketbase';

// @ts-ignore
import googleTransliterate from 'google-input-tool';
import DropDownList from './DropDownList';
import AddTag from './AddTag';

// Initialize PocketBase client globally
const pb = new PocketBase(process.env.REACT_APP_PKT_URL);







const CreateContent = () => {
  
  
    const [upload, setUpload] = useState(['Upload Blog', 'black']);
    const [data, setData] = useState({ Title: '', Subtitle: '' });
    
    const [bookId, setBookId] = useState('');
    
    const [tags, setTags] = useState([]);

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    

    const [bg, setBg] = useState('bg-zinc-100');
    const [textCol, setTextCol] = useState('text-black');


    
    const [theme, setTheme] = useState('bubble'); // Default theme
    const editorRef = useRef(null);
    const quillRef = useRef(null); // Reference for the Quill editor instance
    const [language, setLanguage] = useState('pa-t-i0-und');




    // Initialize Quill Editor
    useEffect(() => {
        if (!editorRef.current || quillRef.current) return; // Avoid reinitialization

        quillRef.current = new Quill(editorRef.current, {
            theme: theme,
            placeholder: 'Compose an epic...',
            modules: {
                toolbar: [
                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                    [{ 'size': [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['link'],
                    ['clean'],
                    [{ 'align': [] }],
                ],
            },
        });

        quillRef.current.root.innerHTML = text || 'Write here...'; // Set initial value

        quillRef.current.root.addEventListener('keydown', async (event) => {
            if (event.keyCode === 32) { // Space key
                const fullText = quillRef.current.getText().trim();
                const words = fullText.split(/\s+/);
                const lastWord = words[words.length - 1];

                const inputLanguage = language; // Language code for Punjabi transliteration
                const maxResult = 1;
                const request = new XMLHttpRequest();

                try {
                    const response = await googleTransliterate(request, lastWord, inputLanguage, maxResult);
                    const transliteratedWord = response[0][0] || lastWord;
                    
                    // Replace the last word with the transliterated word
                    words[words.length - 1] = transliteratedWord;
                    const updatedText = words.join(' ') + ' ';

                    // Get the position of the last word and update only that part
                    const lastIndex = fullText.lastIndexOf(lastWord);
                    quillRef.current.deleteText(lastIndex, lastWord.length); // Remove the last word
                    quillRef.current.insertText(lastIndex, transliteratedWord + ' '); // Insert transliterated word with space

                    // Set the cursor position after the inserted text
                    quillRef.current.setSelection(lastIndex + transliteratedWord.length + 1);

                    const savetext = quillRef.current.root.innerText; 
                    setText(savetext);
                    console.log(text);

                } catch (error) {
                    console.error('Error transliterating text:', error);
                }
            }
        });
    }, [theme]);

    
    
    
    
    
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        const userId = localStorage.getItem('userId');
        const writerId = localStorage.getItem('writerId');


        const data = {
            title: title,
            text: text,
            book_id:bookId && bookId,
            tags:tags,
            writer_id:writerId,
            user_id:userId,
        
        };

        try {
            const record = await pb.collection('content').create(data);
            setTitle('');
            setText('');
            setUpload(['Content Created', 'Green']);

            
        } catch (error) {
            // setError('Error creating post. Please try again.');
            // setSuccess(null);
        }
    };



    const writerId = localStorage.getItem('writerId');



    const toggleTheme = () => {
        setTheme(prevTheme => (prevTheme === 'bubble' ? 'snow' : 'bubble'));
    };

    return (
        <div className="absolute bottom-0 overflow-scroll left-0 py-20  container w-full h-[100vh] flex flex-wrap items-center justify-center">
            
            
            <div className={`w-[15vw] bg-zinc-700 h-[50vh] fixed top-[10%] left-[5%] rounded-xl border border-zinc-500 p-2`}>

            <div className='space-x-2'>
            <p>Background Color</p>
            <button className='size-14 bg-zinc-100 rounded-full' onClick={()=>{setBg('bg-zinc-100'); setTextCol('text-zinc-800')}} ></button>
            <button className='size-14 bg-zinc-400 rounded-full' onClick={()=>{setBg('bg-zinc-400'); setTextCol('text-black')}} ></button>
            <button className='size-14 bg-zinc-800 rounded-full' onClick={()=>{setBg('bg-zinc-800'); setTextCol('text-white')}} ></button>
            </div>


            <div className='px-5 flex flex-col justify-between mt-10'>
                        <button onClick={toggleTheme} className="my-2 py-3 px-9 text-white rounded-full bg-blue-500">
                            Toggle Theme
                        </button>
                        <button 
                            onClick={handleSubmit} 
                            className={`my-2 py-3 px-9 text-white rounded-full duration-200 ${upload[1] === 'green-700' ? 'bg-green-700' : 'bg-black'}`}
                        >
                            {upload[0]}
                        </button>
                    </div>


            </div>
            
            
            <div className={`min-h-[130vh] w-[50vw] ${bg} ${textCol} flex flex-col items-center p-5 space-y-0 text-lg`}>
                
                <div className='h-full bg-gray-300 space-y-3 w-full'>
                    <div className='text-2xl w-full h-[50%] border'>
                        <textarea 
                            required 
                            className=' w-full h-full p-1 rounded-md' 
                            placeholder='Title' 
                            onChange={(e) => setTitle(e.target.value)} 
                            value={title} 
                        />
                    </div>
                </div>

                <div className='bg-slate-200 min-h-[50vh] border-0 text-5xl w-full'>
                    <div ref={editorRef} className={`min-h-[50vh] w-full bg-slate-500`}>
                        {/* Quill Editor */}
                    </div>
                </div>

                <div className='w-full h-full bg-gray-000  flex flex-col items-center justify-center'>
                    
                    <DropDownList categoryId={bookId} setCategotyId={setBookId} collectionName='books' filter={`writer_id="${writerId}"`} />
                    <AddTag tags={tags} setTags={setTags}/>
                </div>
            </div>
        </div>
    );
};

export default CreateContent;
