import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import { Link } from 'react-router-dom';
import ImageUpload from '../Components/ImageUpload';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);



function UserProfileSetup({data}) {

    const [show, setShow] = useState({image:false, username:true, primary:false, secondry:false,});    
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const userId = localStorage.getItem('userId');


    const [profile, setProfile] = useState({
        name: '',
        about: '',
        gender: '',
        age: 0,
        dob: null,
        type: '',
    });




    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value
        }));
    };







    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await pb.collection('writer').update(userId, profile);


            setSuccess('Signup successful! Please check your email for verification.');
            setError('');
        } catch (err) {
            setSuccess('');
            if (err.response && err.response.code === 400) {
                setError(`Password: ${err.response.data.password.message}`);
            } else {
                setError('An unexpected error occurred. Please try again.');
            }
            console.log(err.response.data.password.message);
        }
    };

    return (
        <div className="fixed h-[100vh] w-full flex flex-col items-center justify-center bg-black">


            <div className='w-full bg-zinc-500 h-[30vh] justify-center items-center flex space-x-10' >

            <div className='size-[5vw] rounded-full bg-white flex flex-col items-center justify-center text-2xl font-black'>
                <p> 1</p>
            </div>

            <div className='size-[5vw] rounded-full bg-white flex flex-col items-center justify-center text-2xl font-black'>
                <p> 2</p>
            </div>

            <div className='size-[5vw] rounded-full bg-white flex flex-col items-center justify-center text-2xl font-black'>
                <p> 3</p>
            </div>


            </div>






            <div className='w-full bg-zinc-100 h-[30vh]' >



            {show.image && <ImageUpload/>}

            {show.username && <UsernameApi setProfile={setProfile} profile={profile}/>}
            {show.primary && <Setup handleChange={handleChange} handleSubmit={handleSubmit} setProfile={setProfile} profile={profile}/>}



            </div>

            <div className='w-full bg-zinc-100 h-[30vh]' >

            </div>





            

        </div>
    );
};








function Setup({handleChange, setProfile, profile, handleSubmit}){


return(

                        <div className="h-[80vh] my-10 bg-white p-8 rounded shadow-md w-full max-w-md">
        
                            <h1 className="text-2xl font-bold mb-4">Short Profile Setup</h1>
                
                            <form onSubmit={handleSubmit}>
        
                                {/* <div className="mb-4">
                                <label className="block text-gray-700">Username</label>
                                <input
                                    type="text"
                                    className="mt-1 p-2 w-full border rounded"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                             */}
        
        <div>
                                <label className=" text-sm font-medium">Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={profile.name}
                                    onChange={handleChange}
                                    className="mt-1 w-full border rounded p-2"
                                />
                                </div>
        
                                <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded"> Sign Up </button>
                            </form>
                        </div>
                    
)


}



















const UsernameApi = ({setProfile, profile}) => {
    
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(''); // Clear previous errors

        try {
            const userId = localStorage.getItem('userId');

            // Use the update method to update the username
            await pb.collection('users').update(userId, { username: value });
            setSuccess('Success')
            setProfile({...profile, username:false})

        } catch (err) {
            // Handle specific PocketBase error
            if (err?.data?.code === 400) {
                setError('Oops! Username exists, try another.');
                console.log('value', value);
            } else {
                setError('An unexpected error occurred. Please try again.');
            }
        }
    };

    return (
        <div className="container mx-auto max-w-sm p-4 bg-gray-200 rounded-xl flex flex-col justify-center items-center">
            <form onSubmit={handleSubmit} className="mb-4 flex flex-col justify-center items-center">
                <div className="mb-4 flex flex-col justify-center items-center w-full">
                    <label htmlFor="value" className="block text-sm font-medium text-gray-700">
                        Create Username
                    </label>
                    <input
                        id="value"
                        type="text"
                        value={value}
                        onChange={(e) => { setValue(e.target.value); setError(''); }}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <button
                    type="submit"
                    className="px-4 py-2 bg-orange-600 text-white font-medium rounded-full hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200"
                >
                    Submit
                </button>
            </form>
            {error && (
                <div className="mt-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
                    {error}
                </div>
            )}
        </div>
    );
};






export default UserProfileSetup;
