import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import { fetchDataSingle } from '../api/api';

import { IconEdit, IconClose } from './icons';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);









const Education = ({ writerId }) => {


    const [education, setEducation] = useState(null);
    const [editingId, setEditingId] = useState(null);
    const [open, setOpen] = useState({ profileEdit: false, usernameEdit: false });


    const [formData, setFormData] = useState({
        course: '',
        institute: '',
        start: '',
        end: '',
        grade: '',
    });



    //toggle button function
    const toggleEdit = (key) => {
        setOpen((prev) => ({ ...prev, [key]: !prev[key] }));        
    };



    useEffect(() => {

        async function getData() {

            const data = await fetchDataSingle('writer', writerId, 'education, user_id');

            if (data) {
                setEducation(data.expand.education);
            }
        }
        getData();
    }, []);



    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };



    const handleSubmit = async (e) => {
    
        e.preventDefault();
    
        if (editingId) {
            // Edit the existing location
            await pb.collection('education').update(editingId, formData);
            setEditingId(null);
            toggleEdit('profileEdit')

        } else {
            // Create a new education
            const likeRecord = await pb.collection('education').create(formData);
            const postRecord = await pb.collection('writer').update(writerId, { 'education+': [likeRecord.id] });
            toggleEdit('profileEdit')

        }
        setFormData({
            course: '',
        institute: '',
        start: '',
        end: '',
        grade: ''
        });
        // Refresh the education list
        const data = await fetchDataSingle('writer', writerId, 'books, location, education, user_id');
        setEducation(data.expand.location);
    };



    const handleEdit = (id) => {
        toggleEdit('profileEdit')
        const location = education.find(loc => loc.id === id);
        setFormData(location);
        setEditingId(id);
    };

    const handleDelete = async (id) => {
        const confirmed = window.confirm("Are you sure you want to delete?");
        if (confirmed) {
            await pb.collection('education').delete(id);
            setEducation(education.filter(loc => loc.id !== id));
        }
    };
    

    return (
        <div className="container mx-auto p-4">

            {open.profileEdit &&

                <div className='fixed top-0 left-0 bg-black/90 h-[100Vh] w-[100vw] flex justify-center items-center backdrop-blur-sm'>

                    <div className='flex'>
                    <div className='w-[30vw] mx-auto bg-white p-10 rounded-xl'>
                        <h2 className="text-2xl font-bold mb-4">{editingId ? 'Edit Location' : 'Add Location'}</h2>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <input type="text" name="course" value={formData.course} onChange={handleChange} placeholder="course" className="w-full p-2 border border-gray-300 rounded" />
                            <input type="text" name="institute" value={formData.institute} onChange={handleChange} placeholder="institute" className="w-full p-2 border border-gray-300 rounded" />
                            <input type="text" name="start" value={formData.start} onChange={handleChange} placeholder="start" className="w-full p-2 border border-gray-300 rounded" />
                            <input type="text" name="end" value={formData.end} onChange={handleChange} placeholder="end" className="w-full p-2 border border-gray-300 rounded" />        
                            <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">{editingId ? 'Update Location' : 'Add Location'}</button>
                        </form>
                    </div>
                    <div className='flex justify-start items-start bg-slate-000 px-3'>
                        <button onClick={() => toggleEdit('profileEdit')}>
                        {<IconClose ratio='15'/>}
                        </button>
                    </div>
                    </div>
                </div>
            }

            <h2 className="text-2xl font-bold mt-8 mb-4">Education</h2>
            
            {education && <ul className="space-y-4">
                {education.map(edu => (
                    <li key={edu.id} className="p-4 border border-white rounded bg-white space-y-1">
                        {edu.course &&<p><strong>course:</strong> {edu.course}</p>}
                        {edu.institute && <p><strong>institute:</strong> {edu.institute}</p>}
                        {edu.start && <p><strong>start:</strong> {edu.start}</p>}
                        {edu.end && <p><strong>end:</strong> {edu.end}</p>}
                    
                        <div className='pt-2 space-x-2'>
                            <button onClick={() => handleEdit(edu.id) }>{<IconEdit ratio='15'/>}</button>
                            <button onClick={() => handleDelete(edu.id)}>{<IconClose ratio='15'/>}</button>
                        </div>
                    </li>
                ))}
            </ul>}
            <button onClick={() => toggleEdit('profileEdit')} className="bg-blue-500 text-white p-2 rounded mt-4" >
                {open.profileEdit ? 'Close Edit' : 'Add Location'}
            </button>

        </div>
    );
};

export default Education;
