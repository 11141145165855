import React, { useEffect, useState } from 'react';
import PocketBase from 'pocketbase';
import { Link } from 'react-router-dom';
import ImageView from '../Components/ImageView';
import ImageUpload from '../Components/ImageUpload';
import ManageBooks from '../Components/ManageBooks';
import Posts from './Posts';
import CreatePost from '../Components/CreatePost';

import { IconClose } from '../Components/icons';
import OverlayWindow from '../Components/OverlayWindow';
import CreateContent from '../Components/CreateContent';
import UserProfileSetup from './UserProfileSetup';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);


const UserProfile = () => {

    const [user, setUser] = useState(null);
    const [setup, setSetup] = useState(false);
    
    const [writer, setWriter] = useState(null);
    const [location, setLocation] = useState(null);
    const [followers, setFollowers] = useState(null);
    const [following, setFollowing] = useState(null);
    const [followingId, setFollowingId] = useState(null);

    const userId = localStorage.getItem('userId');

    const [open, setOpen] = useState({ profileEdit: false, usernameEdit: false });

    const toggleEdit = (key) => {
        setOpen((prev) => ({ ...prev, [key]: !prev[key] }));
    };

    useEffect(() => {
        const fetchUser = async () => {
            if (userId) {
                try {
                    const userData = await pb.collection('users').getOne(userId, { expand: 'writer_id, writer_id.location, writer_id.followers, writer_id.following' });
                    setUser(userData);
                    

                    // userData.expand.writer_id ? setWriter(userData.expand.writer_id) : setCreate(true) ;
                    setWriter(userData.expand.writer_id);
                    setLocation(userData.expand.writer_id.expand.location);
                    setFollowing(userData.expand.writer_id.expand.following);
                    setFollowers(userData.expand.writer_id.expand.followers);
                    setFollowingId(userData.expand.writer_id.following);

                    localStorage.setItem('writerName', userData.expand.writer_id.name);
                    
                    if (writer && writer.profile_image === null && writer.about === null) {
                        setSetup(true); // Set setup to true if both are empty
                    }

                    console.log(userData);
                    console.log(location);
                    console.log(followers);



                } catch (err) {
                    console.error('Failed to fetch user data:', err);
                }
            }
        };

        fetchUser();
    }, [userId]);

    if (!user) {
        return <div>Loading...</div>;
    }











    const mobile = <div className="h-[92vh] overflow-scroll bg-zinc-900 overflow-hidden">

    <div className="w-full h-max ">


                
                {setup && <UserProfileSetup/>}


            <div className='space-y-3 w-full overflow-scroll py-10 flex flex-col items-center bg-gray-000 border-l border-gray-700'>


                {writer &&

                    <div className='w-[80vw] h-auto bg-white p-5 rounded-md '>
                        <div className='flex flex-col justify-center items-center  space-y-4'>
                            <p><strong>Profile</strong></p>


                            {writer.profile_image == '' ? <ImageUpload collection={writer.collectionId} id={writer.id} imageField='profile_image' /> :
                                <ImageView collectionId={writer.collectionId} id={writer.id} image={writer.profile_image} type='' />
                            }

                            <p>{user.expand && user.expand.writer_id && user.expand.writer_id.name}</p>
                            <Link to={`/profile/${writer.id}`}>View Profile</Link>
                        </div>
                    </div>

                }

                <div className='w-[80vw] h-auto bg-white p-5 rounded-md'>
                    <div className='flex flex-col justify-center items-center  space-y-4'>
                        <FollowView data={followers} buttonName='Followers' />
                        <FollowView data={following} buttonName='Following' />
                    </div>
                </div>

                <div className='w-[80vw] h-auto bg-white p-5 rounded-md flex justify-center'>
                    <Link className='text-center' to={`/collection/posts`}>Collection</Link>
                </div>
                
                <div className='w-[80vw] h-auto bg-white p-5 rounded-md flex justify-center'>
                    <Link className='text-center' to={`/profile/edit/${user.writer_id}`}>Edit Profile</Link>
                </div>

            </div>
    </div>



    {/*Center*/}

    <div className='flex flex-col h-max w-[100vw]'>
        <div className=' h-[20vh] flex justify-center items-center'>
            <CreatePost />
            <div className='bg-white text-black rounded-full size-[3.5vw] justify-center items-center '>
                <OverlayWindow btnName='Add' > 
                    <CreateContent/>
                </OverlayWindow>
            </div>
        </div>
        <div className=" w-full bg-red-000 flex justify-center items-center ">
            <Posts data1={followingId} Fetchtype='feed' />
        </div>
    </div>







    <div className=" p-8 bg-gray-000 rounded shadow-md  h-screen overflow-scroll border-l border-gray-700">
    </div>



</div>;















    const desktop = <div className="h-[92vh] grid grid-cols-4 bg-zinc-900 overflow-hidden">


            <UserProfileSetup data={user}/>


            <div className="w-full h-[92vh] overflow-scroll border-r border-gray-700">
                <div className="w-full h-full flex flex-col  justify-center items-center space-y-4 bg-gray-000 overflow-hidden">


                    <div className='space-y-3 w-full overflow-scroll py-10 flex flex-col items-center bg-gray-000 border-l border-gray-700'>


                        {writer &&

                            <div className='w-[20vw] h-auto bg-white p-5 rounded-md '>
                                <div className='flex flex-col justify-center items-center  space-y-4'>
                                    <p><strong>Profile</strong></p>


                                    {writer.profile_image == '' ? <ImageUpload collection={writer.collectionId} id={writer.id} imageField='profile_image' /> :
                                        <ImageView collectionId={writer.collectionId} id={writer.id} image={writer.profile_image} type='' />
                                    }

                                    <p>{user.expand && user.expand.writer_id && user.expand.writer_id.name}</p>
                                    <Link to={`/profile/${writer.id}`}>View Profile</Link>
                                </div>
                            </div>

                        }

                        <div className='w-[20vw] h-auto bg-white p-5 rounded-md'>
                            <div className='flex flex-col justify-center items-center  space-y-4'>
                                <FollowView data={followers} buttonName='Followers' />
                                <FollowView data={following} buttonName='Following' />
                            </div>
                        </div>

                        <div className='w-[20vw] h-auto bg-white p-5 rounded-md flex justify-center'>
                            <Link className='text-center' to={`/collection/posts`}>Collection</Link>
                        </div>
                        
                        <div className='w-[20vw] h-auto bg-white p-5 rounded-md flex justify-center'>
                            <Link className='text-center' to={`/profile/edit/${user.writer_id}`}>Edit Profile</Link>
                        </div>

                    </div>
                </div>
            </div>



            {/*Center*/}

            <div className='col-span-2 overflow-hidden flex flex-col h-[92vh] w-[50vw]'>
                <div className='w-full h-[15vh] border-b border-gray-700 flex justify-center items-center'>
                    <CreatePost />
                    <div className='bg-white text-black rounded-full size-[3.5vw] justify-center items-center '>
                        <OverlayWindow btnName='Add' > 
                            <CreateContent/>
                        </OverlayWindow>
                    </div>
                </div>
                <div className=" p-8 pb-11 h-[100%] col-span-2 overflow-scroll w-full">
                    <Posts data1={followingId} Fetchtype='feed' />
                </div>
            </div>







            <div className=" p-8 bg-gray-000 rounded shadow-md  h-screen overflow-scroll border-l border-gray-700">
            </div>



        </div>;


    return window.innerWidth <= 426 ? mobile : desktop;


};



























function FollowView({ data, buttonName }) {

    const [open, setOpen] = useState(false);

    const toggleEdit = () => {
        setOpen(!open);
    };


    function WriterCard({ data }) {

        const { name, id, collectionId, profile_image, image, about, expand = {}, age, gender } = data;

        const imageUrl = `${process.env.REACT_APP_PKT_URL}/${collectionId}/${id}/${profile_image}`;

        return (
            <div className='w-[85%] h-[15vh] rounded-xl p-1 font bg-white m-1 shrink-0 flex space-y-1 overflow-hidden'>
                <Link to={`/${id}/${name.replace(/\s+/g, '-')}`} className='flex justify-center items-center space-x-5 p-3'>
                    <ImageView collectionId={collectionId} id={id} image={profile_image} type='sm' />
                    <p className='text-md font-semibold text-center'>{name}</p>
                </Link>
            </div>
        );
    }

    return (

        <>

            <button disabled={!data || data.length === 0} className='bg-gray-200 hover:bg-gray-300 px-5 font-semibold py-2 rounded-full' onClick={toggleEdit}>{buttonName} {data && data.length || '0'}</button>


            {open &&

                <div className="fixed bottom-0 left-0 bg-black/90  w-[100vw] h-full flex justify-center items-center backdrop-blur-sm">
                    <div className='flex flex-col h-[70vh] w-[80vw] md:w-[40vw] rounded-md bg-black border border-gray-700'>

                        <div className="flex justify-between items-center bg-slate-000 p-3 border-b border-gray-700">
                            <p className='text-white font-bold'>Following {data && data.length}</p>
                            <button onClick={toggleEdit}>{<IconClose ratio="15" />}</button>
                        </div>

                        <div className="flex items-center bg-gray-000 overflow-scroll bg-pink-000 h-[70vh] w-full flex-col py-5">

                            {data.map((item, index) => (<WriterCard data={item} />))}
                        </div>


                    </div>

                </div>
            }

        </>
    );
}







function Feed({ data, usersId }) {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        async function fetchFeed() {
            const userId = usersId;
            const followees = data
            console.log('idssss', data);
            let allPosts = [];

            for (const followee of followees) {
                const userPosts = await pb.collection('post').getFullList({
                    filter: `writer_id = "${followee}"`,
                    sort: '-created'
                });

                allPosts = [...allPosts, ...userPosts];
            }

            // Sort all posts by created_at to ensure latest posts are first
            allPosts.sort((a, b) => new Date(b.created) - new Date(a.created));


            setPosts(allPosts);
            console.log(allPosts);
        }

        fetchFeed();
    }, []);





    return (
        <div className="feed-container space-y-5">
            {posts.map((post, index) => (
                <div key={index} className="border border-gray-200 p-5">
                    <p>{post.text}</p>
                    <small>{new Date(post.created).toLocaleString()}</small>
                </div>
            ))}
        </div>
    );
}







export default UserProfile;
