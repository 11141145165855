import PocketBase from 'pocketbase';
import { useEffect, useState } from 'react';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);






function LikeView({ filter = '' }) {

    const [like, setLike] = useState([]);

  useEffect(() => {
    
    async function fetchLikeData() {
    
      try {
        const data = await pb.collection('like').getFullList({
          expand: 'user_id, post_id',
          filter: `post_id="${filter}"`, // Ensure the filter object is passed correctly
        });
        
        setLike(data);
        console.log('Fetched Data:', data);
        console.log('Filter:', `post_id="${filter}"`);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchLikeData();
  }, [filter]);

  return (
    <>
      <p className='text-blue-500'>{like && like.length > 0 ? like.length : 0}</p> 
    </>
  );
}

export default LikeView;
