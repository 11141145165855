import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchDataSingle } from '../api/api';
import BackButton from '../Components/BackButton';
// import { ClassicEditor, Essentials, Bold, Italic, Font, Paragraph } from 'ckeditor5';

// import 'ckeditor5/ckeditor5.css';

// ClassicEditor
//     .create(document.querySelector('#editor'), {
//         plugins: [Essentials, Bold, Italic, Font, Paragraph],
//         toolbar: {
//             items: [
//                 'undo', 'redo', '|', 'bold', 'italic', '|',
//                 'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor'
//             ]
//         }
//     })
//     .then( /* ... */)
//     .catch( /* ... */);


function ContentView() {

    const [record, setRecord] = useState({});
    const { contentId } = useParams();
    const [tags, setTags] = useState();



    useEffect(() => {
        async function getData() {
            const data = await fetchDataSingle('content', contentId, 'writer_id, publisher_id, tags, content,');
            if (data) {

                setRecord(data);
                setTags(data.expand && data.expand.tags)
            }
        }
        getData();
    }, [contentId]);


    return (
        <div className='w-full h-[92vh] bg-zinc-900 overflow-scroll '>
            <div className='bg-zinc-200 w-[95vw] md:w-[75vw] lg:w-[65vw] h-auto flex-col justify-end items-end rounded-2xl my-10 p-5 overflow-hidden mx-auto'>
               
               
                {/* <div className='w-full h-[5vh] bg-slate-100 space-y-[20vh]'>
                    
                <Link className='w-full' to={`/book/${contentId}`}>
                Back</Link>
                </div>
                 */}

                <div className='my-10 text-center'>
                <p className='text-3xl font-extrabold'>{record.title}</p>
                </div>
                
                <div className='px-3 md:px-10'>
                <p>{record.text}</p>
                </div>
                
                <div className='flex justify-center space-x-3 my-10'>
                    {tags && tags.map((tag, index) => (<TagCard key={index} data={tag} />))}
                </div>

            </div>

            <div className='w-full flex justify-center my-16'>
            <BackButton color='white' size='size-[15vw] md:size-[3vw]'/>
            </div>

        </div>
    );
}








function LinkCard({ data }) {

    return (
        <>
            <button className='w-full  hover:text-orange-600' to={`/content/${data.id}`}>{data.title}</button>
        </>
    )
}





function TagCard({ data }) {



    return (
        <div>
            <p className='text-xs border border-1 border-zinc-400 bg-zinc-300 rounded-full px-5 pb-1'>{data.tag_name}</p>
        </div>
    )
}





export default ContentView;
