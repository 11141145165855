import PocketBase from 'pocketbase';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddTag from './AddTag';
import DropDownList from './DropDownList';
import { IconClose, IconEdit, IconView } from './icons';
import ImageUpload from './ImageUpload';
import ImageView from './ImageView';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);




const ManageBooks = ({ divStyle }) => {


    const writerId = localStorage.getItem('writerId');

    const [books, setBooks] = useState([]);
    const [editingId, setEditingId] = useState(null);
    
    
    
    
    const [open, setOpen] = useState(false);

    const toggleEdit = () => {
        setOpen(!open);
    };



    const [tags, setTags] = useState([]);
    const [category, setCategory] = useState('');
    const [subject, setSubject] = useState([]);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [formData, setFormData] = useState({
        name: '',
        writer_id: writerId,
        publisher_id: '',
        year: '',
        isbn: '',
        edition: '',
        price: '',
        offered_price: '',
        description: '',
        category: '',
        tags: [],
        script: '',
        language: '',
        pages: '',
        bound: '',
        weight: '',
        content: [],
        sale: false,
    });

    useEffect(() => {
        async function fetchBooks() {
            try {
                const result = await pb.collection('books').getFullList({
                    filter: `writer_id="${writerId}"`,
                    expand: 'publisher_id, tags, book_image, content'
                });
                setBooks(result);
            } catch (error) {
                setErrorMessage('Failed to fetch books.');
            }
        }
        fetchBooks();
    }, [writerId]);


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editingId) {
                await pb.collection('books').update(editingId, { ...formData, category, tags });
                setEditingId(null);
                setSuccessMessage('Book updated successfully.');
            } else {
                const newBook = { ...formData, writer_id: writerId };
                await pb.collection('books').create(newBook);
                setSuccessMessage('Book added successfully.');
            }

            setFormData({
                name: '',
                publisher_id: '',
                year: '',
                isbn: '',
                edition: '',
                price: '',
                offered_price: '',
                description: '',
                category: '',
                tags: [],
                subject: '',
                script: '',
                language: '',
                pages: '',
                bound: '',
                weight: '',
                content: [],
                sale: false,
            });

            toggleEdit();

            const result = await pb.collection('books').getFullList({
                filter: `writer_id="${writerId}"`,
                expand: 'publisher_id, tags, book_image, content'
            });
            setBooks(result);

        } catch (error) {
            setErrorMessage(editingId ? 'Failed to update book.' : 'Failed to add book.');

            // setSuccess('');
            if (error.response && error.response.code === 400) {
                setErrorMessage(`${error.response.code}, ${error.response.message}`);
            } else {
                setErrorMessage('An unexpected error occurred. Please try again.');
            }
            console.log(error.response.data);


        }
    };

    const handleEdit = (id) => {
        const book = books.find(book => book.id === id);
        setFormData(book);
        setEditingId(id);
        setTags(book.tags);
        toggleEdit();
    };

    const handleDelete = async (id) => {
        const confirmed = window.confirm("Are you sure you want to delete?");
        if (confirmed) {
            try {
                await pb.collection('books').delete(id);
                setBooks(books.filter(book => book.id !== id));
                setSuccessMessage('Book deleted successfully.');
            } catch (error) {
                setErrorMessage('Failed to delete book.');
            }
        }
    };

    return (
        <div className='w-full h-[92vh] flex flex-col justify-center items-center'>
            
            
            
            
            <div className={`${divStyle}`}>
                
                
                
                
                
                {open && (
                    <div className="fixed top-0 left-0 bg-black/90 w-[100vw] py-10 h-screen flex justify-center items-center backdrop-blur-sm">
                        <div className="flex overflow-scroll bg-pink-000 h-full">
                            <div className="w-[50vw] h-max mx-auto bg-white p-10 rounded-xl">
                                <h2 className="text-2xl font-bold mb-4">{editingId ? 'Edit Book' : 'Add Book'}</h2>
                                {formData.image === '' ? 
                                    <ImageUpload collection={formData.collectionId} id={formData.id} imageField='image' /> :
                                    <div className='flex justify-center items-center'>
                                        <div className='w-[14vw] flex justify-center items-center'>
                                            <ImageView collectionId={formData.collectionId} id={formData.id} image={formData.image} type='book' />
                                        </div>
                                    </div>
                                }
                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <div className="h-full bg-slate-000">
                                        <div className="space-y-4 bg-slate-000">
                                            <h2 className="text-xl font-semibold mt-10">Primary Information</h2>
                                            <InputSection inputValue='name' formData={formData.name} handleChange={handleChange} />
                                            <InputSection inputValue='publisher_id' formData={formData.publisher_id} handleChange={handleChange} />
                                            <InputSection inputValue='year' formData={formData.year} handleChange={handleChange} />
                                            <InputSection inputValue='isbn' formData={formData.isbn} handleChange={handleChange} />
                                            <InputSection inputValue='edition' formData={formData.edition} handleChange={handleChange} />
                                            <textarea name="description" value={formData.description} onChange={handleChange} placeholder="Description" className="w-full p-2 border border-gray-300 rounded" />
                                            <InputSection inputValue='pages' formData={formData.pages} handleChange={handleChange} />
                                        </div>
                                        <div className="space-y-4 bg-slate-000">
                                            <h2 className="text-xl font-semibold mt-10">Secondary Information</h2>
                                            <InputSection inputValue='language' formData={formData.language} handleChange={handleChange} />
                                            <InputSection inputValue='script' formData={formData.script} handleChange={handleChange} />
                                            <InputSection inputValue='bound' formData={formData.bound} handleChange={handleChange} />
                                            <InputSection inputValue='weight' formData={formData.weight} handleChange={handleChange} />
                                        </div>
                                        <div id='price' className="space-y-4 my-10">
                                            <div className='p-3 border border-gray-600 w-max rounded-full px-5'>
                                                <label htmlFor="sale" className='text-xl'>List for Sale</label>
                                                <input type="checkbox" name="sale" checked={formData.sale} onChange={handleChange} className="ml-2" />
                                            </div>
                                            {formData.sale &&
                                                <>
                                                    <InputSection inputValue='price' formData={formData.price} handleChange={handleChange} type='number' />
                                                    <InputSection inputValue='offered_price' formData={formData.offered_price} handleChange={handleChange} type='number' />
                                                </>
                                            }
                                        </div>
                                        <div id='category' className="space-y-4">
                                            <h2 className="text-xl font-semibold mt-10">Category</h2>
                                            <DropDownList categoryId={category} setCategoryId={setCategory} collectionName='category' />
                                            <h2 className="text-xl font-semibold mt-10">Subject</h2>
                                            <DropDownList categoryId={subject} setCategoryId={setSubject} collectionName='subject' />
                                            <AddTag tags={tags} setTags={setTags} />
                                        </div>
                                        <button type="submit" className="bg-blue-500 text-white py-2 mt-10 px-4 rounded">{editingId ? 'Update Book' : 'Add Book'}</button>
                                    </div>
                                </form>
                            </div>
                            <div className="flex justify-start items-start bg-slate-000 px-3">
                                <button onClick={toggleEdit}><IconClose ratio="15" /></button>
                            </div>
                            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                {successMessage && <p className="text-green-500">{successMessage}</p>}
                        </div>
                    </div>
                )}







                <div className='w-full flex justify-center items-center'>
                    <h2 className="text-2xl font-bold text-white mt-8 mb-4">{books && books.length === 0 ? 'No books Currently' : 'Books'}</h2>
                </div>
                <ul className="space-y-4">
                    {books.map(book => (
                        <li key={book.id} className="p-4 border border-white rounded bg-white space-y-1 flex">
                            <div className='w-[75%]'>
                                <p className='text-2xl'>{book.name}</p>
                                <div className='flex space-x-5'>
                                    <p><strong>Year:</strong> {book.year}</p>
                                    <p><strong>ISBN:</strong> {book.isbn}</p>
                                    <p><strong>Price:</strong> ${book.price}</p>
                                </div>
                            </div>
                            <div className="space-x-2 w-[25%] flex justify-end items-center">
                                <Link to={`/book/${book.id}`}><IconView ratio="15" /></Link>
                                <button onClick={() => handleEdit(book.id)}><IconEdit ratio="15" /></button>
                                <button onClick={() => handleDelete(book.id)}><IconClose ratio="15" /></button>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className='w-full flex justify-center items-center'>
                    <button onClick={toggleEdit} className="bg-zinc-100 text-black p-2 rounded-full px-5 mt-4">
                        {open ? 'Close Edit' : 'Add Book'}
                    </button>
                </div>
            </div>
        </div>
    );
};

function InputSection({ inputValue, handleChange, formData, type = 'text' }) {
    return (
        <div>
            <label htmlFor={inputValue} className='text-sm text-zinc-500'>{inputValue.toUpperCase()}</label>
            <input type={type} id={inputValue} name={inputValue} value={formData} onChange={handleChange} placeholder={`enter ${inputValue}`} required className="w-full text-xl p-2 border-b border-gray-300 rounded" />
        </div>
    );
}

export default ManageBooks;
