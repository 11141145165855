import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { fetchDataSingle } from '../api/api'; // Adjust the path as necessary
import ImageView from '../Components/ImageView';

import BookCard from '../Components/BookCard';
import PocketBase from 'pocketbase';
import PostView from '../Components/PostView';
import { Heading, SubHeading, WriterNameMd, TextMd, TextClip, Buttonprimary } from '../Typography/Typography';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);






function WriterView() {

    const [record, setRecord] = useState({});
    const [books, setBooks] = useState([]);
    const [location, setLocation] = useState([]);
    const [posts, setPosts] = useState([]);
    const [education, setEducation] = useState([]);

    const { writerId } = useParams();


    useEffect(() => {

        async function getData() {

            const data = await fetchDataSingle('writer', writerId, 'books, location, education, user_id');

            if (data.expand) {

                setRecord(data);
                data.expand.books && setBooks(data.expand.books);
                data.expand.location && setLocation(data.expand.location);
                data.expand.education && setEducation(data.expand.education);

                console.log(data);
                console.log('eee', data.expand.books);

            }
        }
        getData();
    }, []);


    const {
        name,
        age,
        user_id,
        about,
        gender,
        profile_image,
        type,
        expand,
        collectionId,
        id,
    } = record;

    return (
        <div className='w-full h-[92vh] bg-zinc-900 overflow-hidden'>


            <div className='bg-gray-000 w-full h-full flex justify-center overflow-scroll rounded-xl'>

                <div className='bg-white w-[85%] my-10 h-max  p-10 font-serif  space-y-5 rounded-3xl'>

                    <div className='items-center flex flex-col space-y-4 text-center h-[50vh]'>

                        <ImageView collectionId={collectionId} id={id} image={profile_image} type='profile' />
                        <p className='text-4xl font-semibold'>{name}</p>
                        <p className='text-4xl font-semibold'>{age == '' ? null : age}</p>

                    </div>


                    <div className='flex flex-col items-center space-y-4 pt-10'>
                        <Heading text='About' />

                        <p className='mx-20 text-center'>{about}</p>
                        {location && <p className='mx-20 text-center'>{location.city} </p>}
                    </div>



                    {books && 
                    <div className='flex flex-col items-center space-y-4 pt-10'>
                        <Heading text='Books' />
                        <div className='h-full p-8 bg-slate-000 flex overflow-auto space-x-6'>
                            {books.map((book, index) => (<LinkCard key={index} data={book} />))}
                        </div>
                    </div>
                    }

 

                    <div className='flex flex-col items-center space-y-4 pt-10'>
                        <Heading text='Creations' />
                        {user_id && <PostView user_id={user_id} />}
                    </div>


                    <Heading text='Personal Details' />



                    <div className='flex flex-col items-center space-y-5 pt-0'>
                        <SubHeading text='From' />
                        {location.map((loc, index) => (<LocationCard key={index} data={loc} />))}
                    </div>


                    { education &&    
                    <div className='flex flex-col items-center space-y-5 pt-0'>
                        <SubHeading text='Education' />
                        {education.map((edu, index) => (<EducationCard key={index} data={edu} />))}
                    </div>
                    }


                </div>
            </div>
        </div>
    );
}





function LinkCard({ data }) {

    return (
        <>
            <Link className='w-full hover:text-orange-600' to={`/book/${data.id}`}>{data.name}</Link>
        </>
    )
}



function EducationCard({ data }) {

    return (
        <div className='space-y-2 m-3'>
            <p className='text-2xl text-center'>{data.course} </p>
            <p className='text-center'>{data.institute} </p>
            <p className='text-center'>{data.start} - {data.end} </p>
            {data.grade && <p className='text-center'>Grade: {data.grade} </p>}

        </div>
    )
}



function LocationCard({ data }) {

    return (
        <div className='space-y-2 m-3'>
            <p className='text-center'>{data.village} </p>
            <p className='text-2xl text-center'>{data.city}, {data.state}, {data.country} </p>
            {/* <p className='text-center'>{data.start} - {data.end} </p>
            {data.grade && <p className='text-center'>Grade: {data.grade} </p>} */}

        </div>
    )
}



// function Heading(props) {
//     return (
//         <div className="w-full h-full flex justify-center items-center text-[#C26A4A]">
//             <h1 className="text-2xl font-semibold">{props.text}</h1>
//         </div>
//     );
// }






export default WriterView;
