import { Link, useParams } from 'react-router-dom';
import Posts from './Posts';
import Content from './Content';
import ManageBooks from '../Components/ManageBooks';




function Collection() {


    const { value } = useParams();
    const writerId = localStorage.getItem('writerId');

    const userId = localStorage.getItem('userId');


    return (
        <div>
            <div className='w-full h-[92vh] bg-zinc-900'>
            
                <div className='bg-zinc-000 rounded-xl py-3 flex flex-col md:flex-row justify-center items-center space-x-3'>
                    <p className='text-white text-2xl font-semibold mb-5 md:mb-0' >Your Collection</p>
                 
                 <div className='flex justify-center items-center space-x-3'>
                    <Link to="/collection/posts" className={`p-2 px-5 text-center w-fit ${value === 'posts' ? 'bg-zinc-100 text-black' : 'text-white'}  rounded-full`}>Posts</Link>
                    <Link to="/collection/articles" className={`p-2 px-5 text-center w-fit ${value === 'articles' ? 'bg-zinc-100 text-black' : 'bg-gray-000 text-white'}  rounded-full`}> Articles </Link>
                    <Link to="/collection/books" className={`p-2 px-5 text-center w-fit ${value === 'books' ? 'bg-zinc-100 text-black' : 'bg-gray-000 text-white'}  rounded-full`}> Books</Link>
                    </div>
                </div>
            
                <div className='col-span-5 overflow-scroll h-[85vh]'>
                    {value == 'posts' && <Posts columnStyle='columns-0 md:columns-2 lg:columns-4' filter={`writer_id = "${writerId}"`}/>}
                    {value == 'articles' && <Content filter={`writer_id = "${writerId}"`}/>}
                    {value == 'books' && <ManageBooks divStyle='w-[70%]'/>}
                
                </div>
            
            </div>
        </div>
    );
}


export default Collection;
