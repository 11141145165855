import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchDataWithFilter, fetchDataForFeed } from '../api/api'; // Adjust the path as necessary
import PocketBase from 'pocketbase';
import TimeView from '../Components/TimeView';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);




function Content({ data1, Fetchtype = '', filter }) {
    const [records, setRecords] = useState([]);
    const [likes, setLikes] = useState({});

    const [loading, setLoading] = useState(true);



    useEffect(() => {
        async function getAllWriters() {
            try {
                let data;

                if (Fetchtype.toLowerCase() === 'feed') {
                    data = await fetchDataForFeed('user_id, writer_id, like', data1);
                } else {
                    data = await fetchDataWithFilter('content', 'user_id, writer_id, like', filter);
                }

                if (data) {
                    setRecords(data);
                    console.log('Fetched all writers:', data);
                    if (data) { setLoading(false); }

                    // Initialize likes
                    const initialLikes = {};
                    data.forEach(post => {
                        initialLikes[post.id] = post.like ? post.like.length : 0;
                    });
                    setLikes(initialLikes);
                }
            } catch (error) {
                console.error('Error fetching writers:', error);
            }
        }
        getAllWriters();
    }, [data1, Fetchtype]);

    return (
        <div className='w-full h-[100vh] bg-slate-000'>


            {loading ? (<div className='flex justify-center items-center w-full bg-black/80 text-white h-[92vh]'><p>Loading</p></div>) :
                <div className='m-5 columns-0 md:columns-2 lg:columns-3'>
                    {records.map((post, index) => (
                        <ContentCard key={index} data={post} likes={likes} setLikes={setLikes} />
                    ))}
                </div>
            }


        </div>
    );
}
















function ContentCard({ data, likes, setLikes, key }) {
    const userId = localStorage.getItem('userId');
    const userExists = data.expand ? data?.expand?.like?.some(likeObj => likeObj.user_id === userId) : false;

    return (
        <div className=' h-max rounded-xl mb-5 py-5 px-5 font bg-white shrink-0 flex flex-col justify-center items-center space-y-5 overflow-hidden'>

            <p className='text-2xl font-semibold line-clamp-5 '>{data.title}</p>
            <p className='text-md  line-clamp-5 '>{data.text}</p>

            <Link className='bg-zinc-200 hover:bg-orange-300 px-4 py-1 rounded-full' to={`/content/${data.id}`}>Read Full</Link>

            {data.expand && data.expand.writer_id &&
                <Link to={`/${data.expand.writer_id.id}/${data.expand.writer_id.name}`}>
                    <p className='text-xs' >- {data.expand.writer_id.name}</p>
                </Link>
            }




            <div className='w-full flex justify-evenly items-center p-0 bg-zinc-200 grid grid-cols-3 '>
                {data.id && (<div className='bg-green-200'>
                    <LikeButton
                        post_id={data.id}
                        user_id={userId}
                        like={data.expand && data.expand.like}
                        userExists={userExists}
                        likes={likes}
                        setLikes={setLikes}
                    />
                </div>)}



                <div className='bg-red-100' >
                    <p className='text-sm' >Published <TimeView created={data.created} /></p>
                </div>

                <div className='bg-red-300'>
                    <p className='text-sm' >By {data.expand && data.expand.writer_id && data.expand.writer_id.name}</p>
                </div>

            </div>


        </div>
    );
}

















function LikeButton({ user_id, post_id, like, likes, setLikes, userExists }) {

    const [liked, setLiked] = useState(userExists);

    const data = {
        user_id: user_id,
        post_id: post_id
    };


    const setLike = async () => {

        try {


            setLiked(true);

            setLikes(prevLikes => ({
                ...prevLikes,
                [post_id]: prevLikes[post_id] + 1
            }));


            const likeRecord = await pb.collection('like').create(data);
            const postRecord = await pb.collection('post').update(post_id, { 'like+': [likeRecord.id] });


        } catch (error) {
            console.error('Error creating like or updating post:', error);
        }
    };


    const setUnlike = async () => {
        try {

            setLiked(false);

            setLikes(prevLikes => ({
                ...prevLikes,
                [post_id]: prevLikes[post_id] - 1
            }));


            const userId = localStorage.getItem('userId');
            const likeRecord = await pb.collection('like').getFirstListItem(`user_id="${userId}" && post_id="${post_id}"`,);
            await pb.collection('like').delete(likeRecord.id);
            const postRecord = await pb.collection('post').update(post_id, { 'like-': [likeRecord.id] });


        } catch (error) {
            console.error('Error creating like or updating post:', error);
        }
    };


    return (

        <>
            <button className={liked ? 'bg-orange-800 text-white py-1 px-3 rounded-full' : 'bg-zinc-200 text-black py-1 px-3 rounded-full'} onClick={liked ? setUnlike : setLike}>
                {liked ? `liked ${likes[post_id] || 0}` : `Like ${likes[post_id] || 0}`}
            </button>
        </>
    );




}

export default Content;
