import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import { fetchDataSingle } from '../api/api';

import { IconEdit, IconClose } from './icons';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);









const Location = ({ writerId }) => {


    const [locations, setLocations] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [open, setOpen] = useState({ profileEdit: false, usernameEdit: false });


    const [formData, setFormData] = useState({
        city: '',
        state: '',
        country: '',
        pincode: '',
        primary: false,
        nearby: '',
        line1: '',
        line2: '',
        village: ''
    });



    //toggle button function
    const toggleEdit = (key) => {
        setOpen((prev) => ({ ...prev, [key]: !prev[key] }));
    };



    useEffect(() => {

        async function getData() {

            const data = await fetchDataSingle('writer', writerId, 'location');

            if (data) {
                setLocations(data.expand.location);
            }
        }
        getData();
    }, [writerId]);



    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };



    const handleSubmit = async (e) => {

        e.preventDefault();

        if (editingId) {
            // Edit the existing location
            await pb.collection('location').update(editingId, formData);
            setEditingId(null);
            toggleEdit('profileEdit')

        } else {
            // Create a new location
            const likeRecord = await pb.collection('location').create(formData);
            toggleEdit('profileEdit')

        }
        setFormData({
            city: '',
            state: '',
            country: '',
            pincode: '',
            primary: false,
            nearby: '',
            line1: '',
            line2: '',
            village: ''
        });
        // Refresh the locations list
        const data = await fetchDataSingle('writer', writerId, 'books, location, education, user_id');
        setLocations(data.expand.location);
    };



    const handleEdit = (id) => {
        toggleEdit('profileEdit')
        const location = locations.find(loc => loc.id === id);
        setFormData(location);
        setEditingId(id);
    };

    const handleDelete = async (id) => {
        const confirmed = window.confirm("Are you sure you want to delete?");
        if (confirmed) {
            await pb.collection('location').delete(id);
            setLocations(locations.filter(loc => loc.id !== id));
        }
    };


    return (
        <div className="container mx-auto p-4">

            {open.profileEdit &&

                <div className='fixed top-0 left-0 bg-black/90 h-[100Vh] w-[100vw] flex justify-center items-center backdrop-blur-sm'>

                    <div className='flex'>
                        <div className='w-[30vw] mx-auto bg-white p-10 rounded-xl'>
                            <h2 className="text-2xl font-bold mb-4">{editingId ? 'Edit Location' : 'Add Location'}</h2>
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <input type="text" name="city" value={formData.city} onChange={handleChange} placeholder="City" className="w-full p-2 border border-gray-300 rounded" />
                                <input type="text" name="state" value={formData.state} onChange={handleChange} placeholder="State" className="w-full p-2 border border-gray-300 rounded" />
                                <input type="text" name="country" value={formData.country} onChange={handleChange} placeholder="Country" className="w-full p-2 border border-gray-300 rounded" />
                                <input type="text" name="pincode" value={formData.pincode} onChange={handleChange} placeholder="Pincode" className="w-full p-2 border border-gray-300 rounded" />
                                <input type="text" name="nearby" value={formData.nearby} onChange={handleChange} placeholder="Nearby" className="w-full p-2 border border-gray-300 rounded" />
                                <input type="text" name="line1" value={formData.line1} onChange={handleChange} placeholder="Line 1" className="w-full p-2 border border-gray-300 rounded" />
                                <input type="text" name="line2" value={formData.line2} onChange={handleChange} placeholder="Line 2" className="w-full p-2 border border-gray-300 rounded" />
                                <input type="text" name="village" value={formData.village} onChange={handleChange} placeholder="Village" className="w-full p-2 border border-gray-300 rounded" />
                                <input type="checkbox" name="primary" checked={formData.primary} onChange={handleChange} className="mr-2" />
                                <label htmlFor="primary">Primary</label>
                                <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">{editingId ? 'Update Location' : 'Add Location'}</button>
                            </form>
                        </div>
                        <div className='flex justify-start items-start bg-slate-000 px-3'>
                            <button onClick={() => toggleEdit('profileEdit')}>
                                {<IconClose ratio='15' />}
                            </button>
                        </div>
                    </div>
                </div>
            }

            <h2 className="text-2xl font-bold mt-8 mb-4">Location</h2>
            <ul className="space-y-4">
                {locations.map((location) => (
                    <li key={location.id} className="p-4 border border-white rounded bg-white space-y-1">
                        {location.village && <p><strong>Village:</strong> {location.village}</p>}
                        <p><strong>City:</strong> {location.city}</p>
                        <p><strong>State:</strong> {location.state}</p>
                        {location.country && <p><strong>Country:</strong> {location.country}</p>}
                        {location.pincode && <p><strong>Pincode:</strong> {location.pincode}</p>}
                        <p><strong>Primary:</strong> {location.primary ? 'Yes' : 'No'}</p>
                        {/* <p><strong>Nearby:</strong> {location.nearby}</p> */}
                        {location.line1 && <p><strong>Line 1:</strong> {location.line1}</p>}
                        {location.line2 && <p><strong>Line 2:</strong> {location.line2}</p>}

                        <div className='pt-2 space-x-2'>
                            <button onClick={() => handleEdit(location.id)}>{<IconEdit ratio='15' />}</button>
                            <button onClick={() => handleDelete(location.id)}>{<IconClose ratio='15' />}</button>
                        </div>
                    </li>
                ))}
            </ul>

            <button onClick={() => toggleEdit('profileEdit')} className="bg-blue-500 text-white p-2 rounded mt-4" >
                {open.profileEdit ? 'Close Edit' : 'Add Location'}
            </button>

        </div>
    );
};

export default Location;
