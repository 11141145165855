import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchDataSingle } from '../api/api'; // Adjust the path as necessary
import AddReview from '../Components/AddReview';



function BookView2() {

    const [record, setRecord] = useState({});
    const { bookId } = useParams();
    const [writer, setWriter] = useState({});
    const [publisher, setPublisher] = useState({});
    const [tags, setTags] = useState();


    const userId = localStorage.getItem('userId');

    useEffect(() => {
        async function getData() {
            const data = await fetchDataSingle('books', bookId, 'writer_id, publisher_id, tags');
            if (data) {
                setRecord(data);
                setWriter(data.expand.writer_id);
                setPublisher(data.expand.publisher_id);
                setTags(data.expand.tags);

                console.log(data);
            }
        }
        getData();
    }, [bookId]);

    const {
        name,
        year,
        isbn,
        pages,
        category,
        image,
        id,
        collectionId,
        edition,
        price,
        offerPrice,
        description,
        language,
        script,
        expand,
        sale,
    } = record;

    // expand && console.log('book:', expand, publisher);

    const imageUrl = image ? `${process.env.REACT_APP_PKT_URL}/${collectionId}/${id}/${image}` : '';

    const writerImage = writer ? `${process.env.REACT_APP_PKT_URL}/${writer.collectionId}/${writer.id}/${writer.profile_image}` : '';


    return (
        <div className='w-full h-[92vh] bg-orange-000 overflow-scroll md:grid md:grid-cols-3'>




            <div className='bg-gray-000 mt-10 md:mt-0 w-full md:h-[92vh] flex justify-center items-center'>
                <div className='bg-white w-[80%] md:w-[25vw] h-[70vh]  overflow-hidden object-contain rounded-lg'>
                    {image ? <img src={imageUrl} alt={image} className='h-full' /> : <img src='' alt={name} className='w-full h-full bg-gray-300 rounded-3xl' />}
                </div>
            </div>




            <div className='bg-gray-000 space-y-20 col-span-2 h-[92vh] flex flex-col py-20 items-center  overflow-auto'>


                    <div className='items-center space-y-4 text-center mb-10'>

                        <p className='text-4xl font-semibold'>{name}</p>
                        <p className='text-xl'>- {writer ? writer.name : 'Unknown'}</p>
                        <p className='text-md'>Publisher {publisher ? publisher.name : 'Unknown'}</p>

                    </div>

                    <div className='flex space-x-5'>
                        <p>Pages: <span className='font-semibold'>{pages}</span></p>
                        <p>Edition: <span className='font-semibold'>{edition}</span></p>
                        <p>Published Year: <span className='font-semibold'>{year}</span></p>
                    </div>


                    {sale &&           
                    <div className='flex space-x-5 items-center border-y-2 w-full justify-center py-4'>
                        <p className='text-xl'>Price: <span className='font-semibold'>{price}/-</span></p>
                        <p className='text-2xl'>Offer Price: <span className='font-semibold'>{offerPrice}/-</span></p>
                    </div>
                    }


                    <div className='flex space-x-5 items-center w-full justify-center py-2'>
                        <p className='text-xl'>Tags:</p>
                        {tags && tags.map((tag, index) => (<TagCard key={index} data={tag} />))}
                    </div>


                    <div className='mx-20 text-center flex flex-col items-center space-y-4 pt-10'>
                        <p className='text-2xl font-semibold text-orange-700'>About the Book</p>
                        <p className='text-md'>{description}</p>
                    </div>




                    {writer && (
                        <div className='flex flex-col items-center space-y-4 pt-10'>
                            <p className='text-2xl font-semibold text-orange-700'>About the Author</p>
                            <div className='h-auto w-[12vw] overflow-hidden aspect-square rounded-full bg-gray-200'>
                                {writer ? <img src={writerImage} alt={writer.name} className='w-full h-[38vh] bg-gray-300 rounded-3xl' /> : <img src='' alt={name} className='w-full h-[38vh] bg-gray-300 rounded-3xl' />}
                            </div>
                            <p className='text-2xl'>{writer.name}</p>
                            <p className='mx-20 text-center'>{writer.about}</p>
                        </div>
                    )}




                    {publisher && (
                        <div className='flex flex-col items-center space-y-4 pt-10 mx-20'>
                            <p className='text-2xl font-semibold text-orange-700'>About the Publisher</p>
                            <div className='h-auto w-[8vw] aspect-square rounded-full bg-gray-200'></div>
                            <p className='text-2xl'>{publisher.name}</p>
                            <p className='text-md'>{publisher.about}</p>

                        </div>
                    )}


                    <div className='h-full w-full pt-10'>

                        <AddReview book_id={id} user_id={userId} data1={record}/>
    
                        </div>



            </div>
        </div>
    );
}









function TagCard({ data }) {



    return (
        <div>
            <p className='font-bold'>{data.tag_name}</p>
        </div>
    )
}





export default BookView2;
