import './App.css';
// import {Route, Routes } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Homepage from './Pages/Homepage';
import Books from './Pages/Books';
import BookView from './Pages/BookView';

import BookView2 from './Pages/BookView2';


import ProfilePrimary from './Pages/ProfilePrimary';
import Navigation from './Components/Navigation';
import BooksCategory from './Pages/BooksCategory';
import Publishers from './Pages/Publishers';
import UserSignup from './Pages/UserSignup';
import UserLogin from './Pages/UserLogin';
import UserProfile from './Pages/UserProfile';
import Writer from './Pages/Writer';
import Posts from './Pages/Posts';
import WriterView from './Pages/WriterView';
import ContentView from './Pages/ContentView';
import UserProfileEdit from './Pages/UserProfileEdit';
import Explore from './Pages/Explore';
import PublisherView from './Pages/PublisherView';
import Collection from './Pages/Collection';









function App() {
  
  const isAuthenticated = !!localStorage.getItem('token');

  return (
    <div>

      <div className='w-full h-full'>
      
      <div className='w-full h-[8vh] overflow-hidden'>
      <Navigation/>
      </div>

      <div className='w-full h-[92vh] bg-red-000 overflow-hidden'>
      
      <Routes>
       
        <Route path="/" element={<Homepage />} />
        <Route path="/books" element={<Books />} />
        <Route path="/books-category/:categoryId" element={<BooksCategory />} />
        <Route path="/publishers" element={<Publishers />} />
        <Route path="/publisher/:publisherId/:publisherName" element={<PublisherView />} />
        
        <Route path="/book/:bookId" element={<BookView2 />} />
        <Route path="/profile/:writerId" element={<WriterView/>} />
        <Route path="/profile" element={<UserProfile/>} />
        
        <Route path="/writers" element={<Writer/>} />
        <Route path="/:writerId/:writerName" element={<WriterView />} />
        <Route path="/content/:contentId" element={<ContentView />} />
        
        
        <Route path="/article" element={<Posts/>} />
        
        <Route path="/collection/:value" element={<Collection/>} />
        <Route path="/collection/:value" element={<Collection/>} />
        <Route path="/collection/:value" element={<Collection/>} />


        <Route path="/read" element={<Explore/>} />
        <Route path="/read/:value" element={<Explore/>} />
        <Route path="/read/:value" element={<Explore/>} />



        <Route path="/signup" element={<UserSignup/>} />
        {/* <Route path="/login" element={<UserLogin/>} /> */}

        <Route path="/login" element={isAuthenticated ? <Navigate to="/profile" /> : <UserLogin/>} />
        <Route path="/profile" element={isAuthenticated ? <UserProfile /> : <Navigate to="/login" />} />
        <Route path="/profile/edit/:userId" element={isAuthenticated ? <UserProfileEdit /> : <Navigate to="/login" />} />
       
        <Route path="*" element={<Navigate to="/" />} />

      </Routes>
      </div>

      </div>


    </div>
  );
}










export default App;
