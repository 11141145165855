import { supabase } from '../client';

import PocketBase from 'pocketbase';


const pb = new PocketBase(process.env.REACT_APP_PKT_URL);


//Fetch All Data 
export async function fetchData(table) {
    const { data, error } = await supabase.from(table).select();
    if (error) {
        console.error(`Error fetching data from ${table}:`, error);
        return null;
    }
    return data;
}





//Fetch Single Data 
export async function fetchSingleData(table, id) {
    let query = supabase
      .from(table)
      .select()
      .eq('id', id)
      .single();
  
    const { data, error } = await query;
  
    if (error) {
      console.error(`Error fetching single record from ${table} with references:`, error);
      return null;
    }
  
    return data;
  }






//getting name from reference ID
export async function getReference(table, column, id) {
    const { data, error } = await supabase
        .from(table)
        .select('*')
        .eq(column, id);
    if (error) {
        console.error(`Error fetching writer name:`, error);
        return null;
    }
    if (data && data.length > 0) {
        return data;
    }
    return null;
}






//Fetch Data along with reference data
// export async function fetchDataWithReferences(table, references = [], limit = 8) {
//     let query = supabase.from(table).select(references.length > 0 ? `*, ${references.join(', ')}` : '*');

//     query = query.limit(limit);

//     const { data, error } = await query;

//     if (error) {
//         console.error(`Error fetching data from ${table} with references:`, error);
//         return null;
//     }

//     return data;
// }



//pocketbase

export async function fetchDataWithReferences(collection, expandCollection) {
  try {
    const data = await pb.collection(collection).getFullList({expand: expandCollection,});

    // const data = await pb.collection(collection).getList(1, 30, { expand: expandCollection })
    // console.log(expandCollection);

    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
}




export async function fetchDataWithFilter(collection, expandCollection, filter = '') {
  try {
    const data = await pb.collection(collection).getFullList({
      expand: expandCollection,
      filter: filter, // Ensure the filter object is passed correctly
    });

    console.log('Expand Collection:', expandCollection);
    console.log('Filter:', filter);

    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
}








export async function fetchDataForFeed(expandCollection, data1) {

  const writerId = localStorage.getItem('writerId');


  const followees = data1.concat(writerId);
  
  console.log('idssss', data1);
  let allPosts = [];

  try {
    for (const followee of followees) {
      const userPosts = await pb.collection('post').getFullList({
        filter: `writer_id = "${followee}"`,
        expand: expandCollection,
        sort: '-created'
      });

      allPosts = [...allPosts, ...userPosts];
    }

    // Sort all posts by created_at to ensure latest posts are first
    allPosts.sort((a, b) => new Date(b.created) - new Date(a.created));

    console.log(allPosts);
    console.log('data feed working');
    return allPosts;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
}












export async function fetchDataSingle(collection, id, expandCollection) {
  try {
    
    const data = await pb.collection(collection).getOne(id, {expand: expandCollection,});

    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
}












