import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);








const DropDownList = ({categoryId, setCategoryId, collectionName, filter=''}) => {
   
    const [item, setItem] = useState('');
    const [data, setData] = useState([]);

    const writerId = localStorage.getItem('writerId');


    useEffect(() => {
        async function fetchBooks() {
            try {
                const result = await pb.collection(collectionName).getFullList({
                    filter: filter,
                    // expand: 'publisher_id, tags, book_image, content'
                });
                setData(result);
                console.log('Books fetched:', result);
            } catch (error) {
                console.error('Error fetching books:', error);
            }
        }
        fetchBooks();
    }, [writerId]);


    const handleChange = async (e) => {
        const { value } = e.target;
        await setCategoryId(value);
        setItem(value);
        console.log('book id dropdown',categoryId, value);
    };

    return (
        <div className="container p-4 space-x-2 text-black">
            {/* <label>Category</label> */}
            <select
                id="Book"
                name="book_id"
                value={categoryId || item}
                onChange={handleChange}
                className='p-3 border rounded-full'
                required
            >
                <option value="">Select</option>
                {data.map((item) => (
                    <option key={item.id} value={item.id}>
                        {item.name}
                    </option>
                ))}
            </select>

        </div>
    );
};

export default DropDownList;
