import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import { Link } from 'react-router-dom';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);



function UserSignup() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== passwordConfirm) {
            setError('Passwords do not match');
            return;
        }

        const data = {
            username,
            email,
            emailVisibility: true,
            password,
            passwordConfirm,
            name,
        };

        try {
            const record = await pb.collection('users').create(data);
            const writerData = await pb.collection('writer').create({'name':data.name, 'user_id':record.id});
            await pb.collection('users').update(record.id, { "writer_id": writerData.id });
            await pb.collection('users').requestVerification(email);

           
            


            const authData = await pb.collection('users').authWithPassword(email, password);
            const record1 = await pb.collection('users').getOne(pb.authStore.model.id);

            // Store auth data in local storage
            localStorage.setItem('authData', JSON.stringify(authData));
            localStorage.setItem('token', pb.authStore.token);
            localStorage.setItem('userId', pb.authStore.model.id);
            localStorage.setItem('writerId', writerData.id);
           
            setSuccess('Signup successful! Please check your email for verification.');
            setError('');
        } catch (err) {
            setSuccess('');
            if (err.response && err.response.code === 400) {
                setError(`Password: ${err.response.data.password.message}`);
            } else {
                setError('An unexpected error occurred. Please try again.');
            }
            console.log(err.response.data.password.message);
        }
    };

    return (
        <div className="h-[92vh] flex items-center justify-center bg-black">
            


            {success ?
            <div className="h-[80vh] my-10 bg-white p-8 rounded text-center shadow-md w-full max-w-md flex flex-col items-center justify-center">
                            <p className="text-black text-2xl mb-4">Welcome <br/>{name}</p>
                            <p className="text-black text-xl mb-4">{success}</p>
                            <Link to={'/profile'}>Goto Profile</Link>
            </div>

:

            <div className="h-[80vh] my-10 bg-white p-8 rounded shadow-md w-full max-w-md">
               
                <h1 className="text-2xl font-bold mb-4">Sign Up</h1>
               
                {error && <p className="text-red-500 mb-4">{error}</p>}
               
                <form onSubmit={handleSubmit}>
                   
                    {/* <div className="mb-4">
                        <label className="block text-gray-700">Username</label>
                        <input
                            type="text"
                            className="mt-1 p-2 w-full border rounded"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                     */}
                    
                    <div className="mb-4">
                        <label className="block text-gray-700">name</label>
                        <input
                            type="text"
                            className="mt-1 p-2 w-full border rounded"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    
                    
                    <div className="mb-4">
                        <label className="block text-gray-700">Email</label>
                        <input
                            type="email"
                            className="mt-1 p-2 w-full border rounded"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Password</label>
                        <input
                            type="password"
                            className="mt-1 p-2 w-full border rounded"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Confirm Password</label>
                        <input
                            type="password"
                            className="mt-1 p-2 w-full border rounded"
                            value={passwordConfirm}
                            onChange={(e) => setPasswordConfirm(e.target.value)}
                            required
                        />
                    </div>
                    
                    
                    
                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white p-2 rounded"
                    >
                        Sign Up
                    </button>
                </form>
            </div>
}
        </div>
    );
};

export default UserSignup;
