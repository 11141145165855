import { Link } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { fetchDataWithFilter, fetchDataForFeed } from '../api/api'; // Adjust the path as necessary
import PocketBase from 'pocketbase';
import { toJpeg } from 'html-to-image';
import { IconClose, IconDownload } from '../Components/icons';
import TimeView from '../Components/TimeView';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);




function Posts({ data1, Fetchtype = '', filter, columnStyle }) {
    const [records, setRecords] = useState([]);
    const [likes, setLikes] = useState({});

    const [loading, setLoading] = useState(true);



    useEffect(() => {
        async function getAllWriters() {
            try {
                let data;
                
                if (Fetchtype.toLowerCase() === 'feed') {
                    data = await fetchDataForFeed('user_id, writer_id, like', data1);
                } else {
                    data = await fetchDataWithFilter('post', 'user_id, writer_id, like',filter);
                }

                if (data) {
                    setRecords(data);
                    console.log('Fetched all writers:', data);
                    if(data){setLoading(false);}

                    // Initialize likes
                    const initialLikes = {};
                    data.forEach(post => {
                        initialLikes[post.id] = post.like ? post.like.length : 0;
                    });
                    setLikes(initialLikes);
                }
            } catch (error) {
                console.error('Error fetching writers:', error);
            }
        }
        getAllWriters();
    }, [data1, Fetchtype]);

    return (
        <div className='w-full h-[100vh] bg-slate-000'>
            
            
            {loading ? (<div className='flex justify-center items-center w-full bg-black/80 text-white h-[92vh]'><p>Loading</p></div>) : 
            <div className={`m-5 ${columnStyle}`}>
                {records.map((post, index) => (
                    <PostCard key={index} data={post} likes={likes} setLikes={setLikes} setRecords={setRecords} records={records}/>
                ))}
            </div>
        }


        </div>
    );
}
















function PostCard({ data, likes, setLikes, key, records, setRecords }) {
    
    const userId = localStorage.getItem('userId');
    const writerId = localStorage.getItem('writerId');
    
    const userExists = data.expand ? data?.expand?.like?.some(likeObj => likeObj.user_id === userId) : false;
    const postRef = useRef(null);

    const [download, setDownload] = useState(false);
    const [color, setColor] = useState('bg-white');

    const downloadAsJpg = () => {
        if (postRef.current === null) {
            return;
        }

        toJpeg(postRef.current, { quality: 1.0, pixelRatio: 4})
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = `${data.text.substring(0, 20)}.jpg`;
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.error('Could not convert to image', err);
            });
    };


    const handleDelete = async () => {
        const confirmed = window.confirm("Are you sure you want to delete?");
        if (confirmed) {
            await pb.collection('post').delete(data.id);
            setRecords(records.filter(post => post.id !== data.id));
        }
    };


    return (
        <div className=' h-max rounded-xl mb-5 py-5 px-5 font bg-white shrink-0 flex flex-col justify-center items-center space-y-5 overflow-hidden'>
            
            <p className='text-md font-semibold'>{data.text}</p>
            {data.expand && data.expand.writer_id &&
                <Link to={`/${data.expand.writer_id.id}/${data.expand.writer_id.name}`}>
                    <p className='text-xs' >- {data.expand.writer_id.name}</p>
                </Link>
            }
            <div className='flex space-x-2'>
                {data.id && (
                    <LikeButton 
                        post_id={data.id} 
                        user_id={userId} 
                        like={data.expand && data.expand.like} 
                        userExists={userExists} 
                        likes={likes} 
                        setLikes={setLikes} 
                        data1={data}
                    />
                )}
            </div>
            <button onClick={()=>setDownload(true)}>
                <IconDownload/>
            </button>
        
            <p className='text-sm' >Posted <TimeView created={data.created}/></p>

            <div className='mx-10 text-center space-y-10'>
            {(data.expand && data.expand.writer_id && data.expand.writer_id.id === writerId) &&
                    <button onClick={handleDelete} className='text-xs' ><IconClose/></button>
            }
            </div>
           


            {download &&         
            <div className='fixed top-0 left-0 bg-black/90 w-full h-full flex justify-center items-center flex flex-col'>
            <div ref={postRef} className={`size-[90vw] md:size-[60vw] lg:size-[30vw] mb-5 py-5 px-5 font ${color} shrink-0 flex flex-col justify-center items-center space-y-5 overflow-hidden`}>
            
            <div className='mx-10 text-center space-y-10'>
            <p className='text-md font-semibold'>{data.text}</p>
            {data.expand && data.expand.writer_id &&
                    <p className='text-xs' >- {data.expand.writer_id.name}</p>
            }
            </div>
            
            </div>
            <div className='flex space-x-3'>
                <button onClick={()=>setColor('bg-white')} className='size-[5vh] bg-white rounded-full'></button>
                <button onClick={()=>setColor('text-white bg-gradient-to-r from-cyan-500 to-blue-500')} className='size-[5vh] bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full'></button>
                <button onClick={()=>setColor('text-white bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500')} className='size-[5vh] bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-full'></button>
            
            
            </div>
            <button onClick={downloadAsJpg} className='mt-3 text-lg px-4 py-1 bg-zinc-500 text-white rounded-full hover:bg-blue-600' >
                Save Image
            </button>
            <button onClick={()=>setDownload(false)} className='mt-3 text-sm px-4 py-1 bg-zinc-500 text-white rounded-full hover:bg-blue-600' >
                Go Back
            </button>
        


        </div>
    }



        </div>
    );
}






















function LikeButton({ user_id, post_id, like, likes, setLikes, userExists, data1 }) {

    const [liked, setLiked] = useState(userExists);

    const data = {
        user_id: user_id,
        post_id: post_id
    };


    const writerId = localStorage.getItem('writerId');
    const writerName = localStorage.getItem('writerName');

    const writer2Id = data1.expand && data1.expand.writer_id && data1.expand.writer_id.id



    const setLike = async () => {

        try {
            
            
            setLiked(true);

            setLikes(prevLikes => ({
                ...prevLikes,
                [post_id]: prevLikes[post_id] + 1
            }));

            
            const likeRecord = await pb.collection('like').create(data);
            const postRecord = await pb.collection('post').update(post_id, { 'like+': [likeRecord.id] });

            const notId = await pb.collection('notification').create({from:writerId, name:writerName,  to:writer2Id, text:'liked your post', collection_id:'post', item_id:post_id});
            const postRecord2 = await pb.collection('writer').update(writer2Id, { 'notification+': [notId.id] });

        } catch (error) {
            console.error('Error creating like or updating post:', error);
        }
    };


    const setUnlike = async () => {
        try {
            
            setLiked(false);

            setLikes(prevLikes => ({
                ...prevLikes,
                [post_id]: prevLikes[post_id] - 1
            }));
            
            
            const userId = localStorage.getItem('userId');
            const likeRecord = await pb.collection('like').getFirstListItem(`user_id="${userId}" && post_id="${post_id}"`,);
            await pb.collection('like').delete(likeRecord.id);
            const postRecord = await pb.collection('post').update(post_id, { 'like-': [likeRecord.id] });

            
        } catch (error) {
            console.error('Error creating like or updating post:', error);
        }
    };


    return (

        <>
            <button className={liked ? 'text-orange-800' : 'text-zinc-500'} onClick={liked ? setUnlike : setLike}>
                {liked ? `liked ${likes[post_id] || 0}` : `Like ${likes[post_id] || 0}`}
            </button>
        </>
    );




}

export default Posts;
