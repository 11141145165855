import React, { useEffect, useState } from 'react';
import PocketBase from 'pocketbase';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);





function FollowButton({data}) {
    
    const  {id, following, followers} = data;

    const writerId = localStorage.getItem('writerId');
    const [followed, setFollowed] = useState(false);    
    
    const userId = writerId;


    useEffect(() => {
        async function checkIfFollowed() {
            try {
                const writer = await pb.collection('writer').getOne(id);
                setFollowed(writer.followers.includes(userId));
            } catch (error) {
                console.error('Error fetching writer data:', error);
            }
        }
        checkIfFollowed();
    }, [id, userId]);






    const handleFollow = async () => {
        try {
            setFollowed(true);

            await pb.collection('writer').update(id, { 'followers+': [userId] });
            await pb.collection('writer').update(userId, { 'following+': [id] });
        } catch (error) {
            console.error('Error following writer:', error);
        }
    };

    const handleUnfollow = async () => {
        try {

            setFollowed(false);

            await pb.collection('writer').update(id, { 'followers-': [userId] });
            await pb.collection('writer').update(userId, { 'following-': [id] });
        } catch (error) {
            console.error('Error unfollowing writer:', error);
        }
    };

    return (
        <>
        {userId && <button
            className={followed ? 'text-white px-3 py-2 border bg-zinc-800 rounded-full text-sm' : 'text-gray-600 text-sm px-3 py-2 border border-gray-500 rounded-full'}
            onClick={followed ? handleUnfollow : handleFollow}
        >
            {followed ? 'You Following' : 'Follow'}
        </button>}
        </>
    );
}

export default FollowButton;
