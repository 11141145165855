import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = ({color, size}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Go back one step in the history
  };


  // const size = 'size-[15vw]';

  return (
    <button onClick={handleBack} className={`btn-back hover:text-orange-400 ${size}`}>
      <svg xmlns="http://www.w3.org/2000/svg"  fill={color} class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg>
    </button>
  );
};

export default BackButton;
