import React, { useState, useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';

const TimeView = ({ created }) => {
    const [timeAgo, setTimeAgo] = useState('');

    useEffect(() => {
        const updateTime = () => {
            setTimeAgo(formatDistanceToNow(new Date(created), { addSuffix: true }));
        };

        // Update the time every minute
        const intervalId = setInterval(updateTime, 60000);

        // Set the initial time
        updateTime();

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [created]);

    return (
        <span>{timeAgo}</span>
    );
};

export default TimeView;
