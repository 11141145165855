import React, { useState } from 'react';
// @ts-ignore
import googleTransliterate from 'google-input-tool';
import PocketBase from 'pocketbase';







const GoogleInputToolComponent = ({ text, setText }) => {

    const [inputText, setInputText] = useState('');
    const [transliteratedText, setTransliteratedText] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1); // -1 means no selection

    const [language, setLanguage] = useState('pa-t-i0-und');


    const handleInputChange = async (event) => {
        const newText = event.target.value;
        setInputText(newText);

        const inputLanguage = language; // Language code for Punjabi transliteration
        const maxResult = 8;
        const request = new XMLHttpRequest();

        try {
            const response = await googleTransliterate(request, newText, inputLanguage, maxResult);
            setTransliteratedText(response);
            setSelectedIndex(-1); // Reset the selected index when input changes
        } catch (error) {
            console.error('Error transliterating text:', error);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setText(prevText => prevText + ' ' + suggestion);
        setInputText('');
        setTransliteratedText([]);
        getSelectOptions();

    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            // Move down in the list
            setSelectedIndex(prevIndex =>
                (prevIndex + 1) % transliteratedText.length
            );
            event.preventDefault(); // Prevent default behavior (like scrolling)
        } else if (event.key === 'ArrowUp') {
            // Move up in the list
            setSelectedIndex(prevIndex =>
                (prevIndex === 0 ? transliteratedText.length - 1 : prevIndex - 1)
            );
            event.preventDefault(); // Prevent default behavior
        } else if (event.key === 'Enter' && selectedIndex >= 0) {
            // Select the suggestion
            handleSuggestionClick(transliteratedText[selectedIndex][0]);
            event.preventDefault(); // Prevent form submission
        }
    };





    async function getSelectOptions() {

        const pb = new PocketBase(process.env.REACT_APP_PKT_URL); // Replace with your PocketBase URL

        try {
            const collection = await pb.collections.getOne('zdms5ruya67j83y');
            const selectField = collection.schema.fields.find(field => field.name === 'category');

            if (selectField && selectField.options) {
                console.log(selectField.options);
                return selectField.options;
            } else {
                console.log("Select field not found or doesn't have options.");
                return [];
            }
        } catch (error) {
            console.error("Error fetching collection schema:", error);
        }
    }







    return (
        <div className="google-input-tool-component">

            <div className='space-x-2 flex justify-evenly items-center my-10'>
                <input
                    type="text"
                    value={inputText}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown} // Handle key events
                    placeholder="Type here..."
                    className="border-2 border-zinc-700 p-2 w-[50%] rounded-full"
                />
                <p>Select Language</p>
                <button className="bg-zinc-600 hover:bg-zinc-700 active:bg-red-700 text-white px-3 py-1 rounded-full focus:bg-red-700" onClick={() => setLanguage('pa-t-i0-und')} >Punjabi</button>
                <button className='bg-zinc-600 hover:bg-zinc-700 text-white px-3 py-1 rounded-full' onClick={() => setLanguage('ur-t-i0-und')} >Urdu</button>
                <button className='bg-zinc-600 hover:bg-zinc-700 text-white px-3 py-1 rounded-full' onClick={() => setLanguage('hi-t-i0-und')} >Hindi</button>
            </div>

            {transliteratedText.length > 0 && (
                <ul className="transliterated-results mt-2 bg-white border p-2 columns-4">
                    {transliteratedText.map((suggestion, index) => (
                        <li
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion[0])}
                            className={`p-1 cursor-pointer ${index === selectedIndex ? 'bg-blue-200' : ''}`}
                        >
                            {suggestion[0]}
                        </li>
                    ))}
                </ul>
            )}


        </div>
    );
};




export default GoogleInputToolComponent;
