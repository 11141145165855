import { useEffect, useState } from 'react';
import { Heading, SubHeading, WriterNameMd, TextMd, TextClip, Buttonprimary } from '../Typography/Typography';
import { fetchData, fetchDataWithReferences } from '../api/api'; // Adjust the path as necessary
import { Link } from 'react-router-dom';
import PocketBase from 'pocketbase';
import LogoutButton from './LogoutButton';
import Notification from './Notification';
import ImageView from './ImageView';


const pb = new PocketBase(process.env.REACT_APP_PKT_URL);

function Navigation() {


  const isAuthenticated = !!localStorage.getItem('token');
  const logo = process.env.PUBLIC_URL + "./likhtum-logo-white.svg";


  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };






  const desktop = <div className='w-full fixed h-[8%] bg-zinc-800 grid grid-cols-6 text-white shadow-xl flex justify-center'>


  <div className='flex space-x-10 border-0 h-full justify-center items-center'>
    <img className='h-[4.5vh]' src={logo} />
  </div>

  <div className='flex space-x-10 col-span-4 border-0 h-full justify-center items-center'>
    <HoverLinkButton link='/' name='Home' />
    <HoverLinkButton link='/writers' name='Writers' />
    <HoverLinkButton link='/books' name='Books' />
    <HoverLinkButton link='/read/posts' name='Read' />
    <HoverLinkButton link='/publishers' name='Publishers' />
  </div>


  <div className='relative flex space-x-10 border-0 h-full justify-center items-center'>

    {isAuthenticated && <Notification/>}
    
    <button className='border p-2 px-5 rounded-full text-white' onClick={toggleOpen}>
        {isAuthenticated ? 'Account' : <Link to={'/login'}>Login</Link>}
    </button>



    {open && isAuthenticated &&
      <div onClick={toggleOpen} className='bg-zinc-800 space-y-3 border p-3 px-5 text-white rounded-md absolute flex flex-col justify-center items-center mx-auto w-[10vw] h-max top-[9vh] right-[3vw]'>
        {isAuthenticated ? <HoverLinkButton link='/profile' name='Profile' /> : <HoverLinkButton link='/login' name='Login' />}
        {isAuthenticated ? <HoverLinkButton link='/collection/posts' name='Collection' /> : <HoverLinkButton link='/login' name='Login' />}
        {isAuthenticated ? <LogoutButton /> : <HoverLinkButton link='/login' name='Login' />}
      </div>
    }

  </div>


</div>;













const mobile = <div className='w-full fixed h-[8%] bg-zinc-800 text-white shadow-xl flex justify-between'>


<div className='flex space-x-10 border-0 h-full justify-center items-center'>
  <img className='h-[4.5vh]' src={logo} />
</div>

<div className='relative flex space-x-10 border-0 h-full justify-center items-center'>

  <button className='border p-2 px-5 rounded-full text-white' onClick={toggleOpen}>{isAuthenticated ? 'menu' : <Link to={'/login'}>Login</Link>}</button>

  {open && isAuthenticated &&
    <div onClick={toggleOpen} className='bg-zinc-800 space-y-3 border p-3 px-5 text-white rounded-md absolute flex flex-col justify-center items-center mx-auto w-[100vw] h-max top-[9vh] right-0'>
      
      
      <HoverLinkButton link='/' name='Home' />
  <HoverLinkButton link='/writers' name='Writers' />
  <HoverLinkButton link='/books' name='Books' />
  <HoverLinkButton link='/read/posts' name='Read' />
  <HoverLinkButton link='/publishers' name='Publishers' />


      
      {isAuthenticated ? <HoverLinkButton link='/profile' name='Profile' /> : <HoverLinkButton link='/login' name='Login' />}
      {isAuthenticated ? <HoverLinkButton link='/collection/posts' name='Collection' /> : <HoverLinkButton link='/login' name='Login' />}
      {isAuthenticated ? <LogoutButton /> : <HoverLinkButton link='/login' name='Login' />}
    </div>
  }

</div>


</div>;









  return window.innerWidth <= 426 ? mobile : desktop;
}






function HoverLinkButton({ link, name }) {


  return (
    <div className='w-max text-xl flex justify-center items-center hover:text-orange-400 duration-150'>
      <Link to={link}>{name}</Link>
    </div>
  );
}


function Logout() {

  pb.authStore.clear();
}



export default Navigation;