import { useEffect, useState } from 'react';
import { Heading, SubHeading, WriterNameMd, TextMd, TextClip, Buttonprimary } from '../Typography/Typography';
import { fetchData, fetchDataWithReferences } from '../api/api'; // Adjust the path as necessary
import BookCard from '../Components/BookCard';

function Homepage() {



  return (
    <div className='w-full h-[92vh] overflow-scroll'>
      <div className='w-full h-[90vh] bg-slate-300'>

      </div>

      <div className='w-full h-full bg-red-000'>

        <SelectedPoetry />
        <Poetry />
        <FeaturedWriter />
        <WriterIntro />
        <BooksSection />


      </div>


    </div>
  );
}





function SelectedPoetry() {
  return (
    <div className='flex items-center justify-center py-10 '>

      <div className='w-[95vw] md:w-[65vw] h-full bg-red-000 grid grid-cols-3 overflow-hidden rounded-3xl shadow-xl'>

        <div className='h-[12vh] bg-green-000 col-span-3'>
          <Heading text='Selected Works' />
        </div>

        <div className='h-full space-y-5 py-5 mx-20 bg-green-000 col-span-3 flex flex-col justify-center items-center'>
          <TextMd text='Selected Workslected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected Works' />
          <WriterNameMd text='Bubu Batalvi' />
        </div>

        <div className='h-full pb-10 border-b-2 bg-green-000 col-span-3 flex flex-col justify-center items-center'>
          <Buttonprimary name='see full Article' />
        </div>

        <div className='h-[12vh] bg-green-000 col-span-3'>
          <SubHeading text='More article from the Writer' />
        </div>


        <div className='h-auto bg-green-000 mx-3 my-2'>
          <TextClip text='Selected Workslected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected Works' />
        </div>

        <div className='h-auto bg-green-000 mx-3 my-2 border-x'>
          <TextClip text='Selected Workslected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected Works' />
        </div>

        <div className='h-auto bg-green-000 mx-3 my-2'>
          <TextClip text='Selected Workslected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected Works' />
        </div>

        <div className='h-[10vh] bg-green-000 col-span-3'>
          <Buttonprimary name='see all work' />
        </div>


      </div>

    </div>
  );
}


function Poetry() {
  return (
    <div className='flex items-center justify-center my-20'>

      <div className='w-[100vw] md:w-[100vw] h-full bg-red-500 grid grid-cols-4'>



        <div className='h-[12vh] bg-blue-600 col-span-4'>
          <Heading text='Eminent Writers' />

        </div>


        <div className='h-[50vh] bg-blue-400'></div>

        <div className='h-[50vh] bg-blue-500'></div>
        <div className='h-[50vh] bg-blue-400'></div>
        <div className='h-[50vh] bg-blue-500'></div>
        <div className='h-[50vh] bg-blue-500'></div>
        <div className='h-[50vh] bg-blue-400'></div>
        <div className='h-[50vh] bg-blue-500'></div>
        <div className='h-[50vh] bg-blue-400'></div>

        <div className='h-[10vh] bg-blue-800 col-span-4'>
          <Buttonprimary name='Go To All Writers' />

        </div>


      </div>

    </div>
  );
}








function FeaturedWriter() {
  
  const [record, setRecord] = useState([]);



  //fetching data
  useEffect(() => {
    async function getData() {
      const data = await fetchData("writers");
      if (data) {
        setRecord(data);
        console.log(data);
      }
    }
    getData();
  }, []);


  //writer Card
  function WriterCard({ data }) {
    const { name, location, imageUrl } = data;

    return (
      <div className='w-[17vw] h-full p-1 bg-slate-000 flex flex-col justify-center items-center space-y-1 overflow-hidden'>
        <img src={imageUrl || ''} alt={name} className='aspect-square rounded-full h-[25vh] bg-slate-400' />
        <p className='text-xl font-semibold'>{name}</p>
        <p className='text-lg'>{location}</p>
      </div>
    );
  }




  return (
    <div className='flex items-center justify-center flex-col'>
      <div className='h-[12vh] w-[80vw] bg-blue-000'>
        <Heading text='Featured Writers' />
      </div>

      <div className='w-[90vw] md:w-[80vw] overflow-x-scroll h-auto py-5 bg-red-000 flex'>
        {record.map((writer, index) => (
          <WriterCard
            key={index}
            data={writer}
          />
        ))}
      </div>

      <div className='h-auto py-5 w-[80vw] bg-blue-000'>
        <Buttonprimary name='See All' />
      </div>
    </div>
  );
}




function WriterIntro() {

  return (
    <div className='flex items-center justify-center my-10'>

      <div className='w-[95vw] md:w-[80vw] h-full bg-red-000 grid grid-cols-3 grid-rows-1'>




        <div className='py-5 bg-orange-000 col-span-3'>
          <Heading text='Introduction with writer' />
        </div>


        <div className='h-[80vh] border rounded-[50px] overflow-hidden bg-blue-000 col-span-3 grid grid-cols-2 shadow-2xl'>


          <div className=' bg-white flex  col-span-2 md:col-span-1 '>

            <div className=' w-[50%] bg-slate-000 flex justify-center items-center'>
              <div className='aspect-square h-[80%] rounded-full bg-red-200'></div>
            </div>

            <div className=' w-[50%] bg-slate-000 flex flex-col pl-5 items-start justify-center'>
              <p className='font-bold text-xl'>Surjit Patar</p>
              <p className='font-semibold text-md'>Writer poet</p>
              <p className='font-semibold text-md'>Ludhiana, Punjab, India</p>
            </div>

          </div>


          <div className='border-l-2 bg-green-000 col-span-2 md:col-span-1 flex flex-col pl-5 items-start justify-center'>
            <p className='font-semibold text-md'>nbbjdfbcvjhdfb nbbjdfbcvjhdfb nbbjdfbcvjhdfb nbbjdfbcvjhdfb vnbbjdfbc vjhdfbn bbjdfbcvjh dfbnbbjdfb cvjhdfbnbb jdfbcvjhdfb nbbjdfbcvj hdfbnbbjdfb cvjhdfb</p>
          </div>


          <div className='border-t-2 bg-red-000 col-span-2 flex flex-col items-center'>
            <p className='font-bold text-xl py-5 text-[#C5A478]'>From the article of Writer</p>
            <div className='mx-10 h-full col-span-2 flex justify-center items-center flex-wrap'>

              <TextMd text='ddjbcbvd sjcbdjcb vjdsvbcbjdsvcjdwvc hgwdvcc hvwdhgcv whgcvhwvxhgwvxs djhcvdhjcvs dhggcvsdhgcv hgdsgvch gdsvchgdsv chgdsvchg dsvchgds cvhgsdcvhgschgvsghcvsdhgcvshdgcv' />
            </div>
            <Buttonprimary name='see more' />
          </div>


        </div>


      </div>

    </div>
  );
}








function BooksSection() {
  
  const [record, setRecord] = useState([]);

  useEffect(() => {
    async function getData() {
      const data = await fetchDataWithReferences('books', 'writer_id, publisher_id, book_image');
      if (data) {
        setRecord(data);
        console.log(data);
      }
    }
    getData();
  }, []);


  return (
    <div className='flex items-center w-full justify-center my-20'>
      <div className='w-[95vw] md:w-[80vw] h-full bg-red-000'>
        <div className='h-[12vh] bg-slate-000 col-span-3'>
          <Heading text='New Release' />
        </div>
        <div className='h-full w-full p-8 overflow-x-scroll bg-slate-000 flex space-x-6'>
         
          {/* {record.map((book, index) => (
          
            <BookCard key={index} data={book} />
          
          ))} */}

        </div>
        <Buttonprimary name='See more' link='/books'/>
      </div>
    </div>
  );
}













export default Homepage;
