


import { Link } from "react-router-dom";




function Heading(props) {
    return (
        <div className="w-full h-full flex justify-center items-center">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-[#C26A4A]">{props.text}</h1>
        </div>
    );
}



function SubHeading(props) {
    return (
        <div className="w-full h-full flex justify-center items-center text-[#C26A4A]">
            <h1 className="text-2xl font-semibold">{props.text}</h1>
        </div>
    );
}


function WriterNameMd(props) {
    return (
        <div className="w-full flex justify-center items-center">
            <p className="text-2xl font-bold">{props.text}</p>
        </div>
    );
}


function TextMd(props) {
    return (
        <div className="w-full h-fit flex text-center justify-center items-center">
            <p className="text-md md:text-xl lg:text-xl font-semibold text-center">{props.text}</p>
        </div>
    );
}



function TextClip(props) {
    return (
        <div className="w-full h-full flex text-center justify-center items-center">
            <p className="text-md md:text-md lg:text-md text-center line-clamp-2 ">{props.text}</p>
        </div>
    );
}



//------------BUttons


function Buttonprimary(props) {
    
    const arrow = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg>;
    
    return (
        
        <Link to={props.link}>
        <div className="group/item w-max my-0 m-auto h-full flex align-top text-center justify-center items-center ">
            <p className="text-md  md:text-xl lg:text-md bg-gray-100 flex hover:bg-gray-300 px-5 py-2 rounded-full group-hover/item:px-10 duration-300">{props.name}
            <span className="text-xl md:text-5xl lg:text-5xl ml-2 group/edit hidden group-hover/item:block text-black duration-300">{arrow}</span>
            </p>
        </div>
        </Link>
    );
}



function LinkButton(props) {
    
    const arrow = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg>;
    
    return (
        
        <Link to={props.link}>
        <div className="group/item w-max my-0 m-auto h-full flex align-top text-center justify-center items-center ">
            <p className="text-md  md:text-xl lg:text-md bg-gray-100 flex hover:bg-gray-300 px-5 py-2 rounded-full group-hover/item:px-10 duration-300">{props.name}
            <span className="text-xl md:text-5xl lg:text-5xl ml-2 group/edit hidden group-hover/item:block text-black duration-300">{arrow}</span>
            </p>
        </div>
        </Link>
    );
}







export { Heading, SubHeading, WriterNameMd, TextMd, TextClip, Buttonprimary };
