import { Link } from 'react-router-dom';








function BookCard({ data }) {

    const { name, id, category, image, expand, published_date } = data;

    if (expand) {
        var writerName = expand.writer_id ? expand.writer_id.name : 'Unknown';
        var publisherName = expand.publisher_id ? expand.publisher_id.name : 'Unknown';
    }
    else {
        var writerName = 'Unknown';
        var publisherName = 'Unknown';
    }
    const imageUrl = image ? `${process.env.REACT_APP_PKT_URL}/api/files/269isyqw9aj12c3/${id}/${image}` : '';

    return (
        <div className='w-[15vw] h-full p-3 shrink-0 flex flex-col justify-start bg-zinc-100 items-center space-y-1 overflow-hidden'>

            <Link className='w-full' to={`/book/${id}`}>
                {imageUrl !== '' ? <img src={imageUrl} alt={imageUrl} className='w-full shrink-0 h-[38vh] bg-gray-300 rounded-xl' /> : <img src='' alt={name} className='w-full h-[38vh] bg-orange-950 rounded-xl flex justify-center items-center text-white text-2xl text-wrap px-5' />}
                <p className='text-xl px-5 pb-3 font-semibold text-center mt-3'>{name}</p>
            </Link>
            
            <Link className='text-md text-zinc-500 hover:text-zinc-800' to={`/${expand && expand.writer_id && expand.writer_id.id}/${writerName.replace(/\s+/g, '-')}`}>{writerName}</Link>
            <Link className='text-md text-zinc-500 hover:text-zinc-800' to={`/publisher/${expand && expand.publisher_id && expand.publisher_id.id}/${publisherName.replace(/\s+/g, '-')}`}>{publisherName}</Link>            
            <p className='text-sm'>{published_date}</p>
        </div>
    );
}

export default BookCard;
