import { useState } from "react";
import { IconClose } from "./icons";

function ImageView({ collectionId, id, image, type }) {



    const imageUrl = `${process.env.REACT_APP_PKT_URL}/api/files/${collectionId}/${id}/${image}`;

    const [open, setOpen] = useState(false);

    const toggleEdit = () => {
        setOpen(!open);
    };


    return (
        <>
            {type === 'book' ? (<img onClick={toggleEdit} src={imageUrl} alt='' className='cursor-pointer w-full h-[38vh] bg-gray-300 rounded-3xl shrink-0' />) :

                type === 'sm' ? (<img onClick={toggleEdit} src={imageUrl} alt='' className='cursor-pointer size-[12vh] bg-gray-300 rounded-full shrink-0 object-cover' />) :

                    (<img onClick={toggleEdit} src={imageUrl} alt='' className='cursor-pointer size-[20vh] bg-gray-300 rounded-full shrink-0 object-cover' />)
            }

            {open && <ImagePreview toggleEdit={toggleEdit} imageUrl={imageUrl} />}

        </>
    );

}





function ImagePreview({ imageUrl, toggleEdit }) {

    return (

        <>
            <div className="fixed top-0 left-0 bg-black/90  w-[100vw] h-full flex justify-center items-center backdrop-blur-sm">

                <div className='size-[70vh] object-cover bg-black'>
                    <img src={imageUrl} alt='' className=' h-full object-cover' />
                </div>

                <div className="p-5 flex justify-start items-start h-[70vh]">
                    <button onClick={toggleEdit}>{<IconClose ratio="15" />}</button>
                </div>
            
            </div>
        </>
    );
}








export default ImageView;
