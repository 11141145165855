import { Link, useParams } from 'react-router-dom';
import Posts from './Posts';
import Content from './Content';




function Explore() {


    const { value } = useParams();


    return (
        <div>
            <div className='w-full h-[92vh] bg-zinc-900'>
            
                <div className='bg-zinc-000 rounded-xl py-3 flex justify-center space-x-1'>
                    <Link to="/read/posts" className={`p-2 px-5 text-center w-fit ${value === 'posts' ? 'bg-zinc-100 text-black' : 'text-white'}  rounded-full`}>Posts</Link>
                    <Link to="/read/articles" className={`p-2 px-5 text-center w-fit ${value === 'articles' ? 'bg-zinc-100 text-black' : 'bg-gray-000 text-white'}  rounded-full`}> Articles </Link>
                </div>
            
                <div className='col-span-5 overflow-scroll h-[85vh]'>
                    {value == 'posts' && <Posts columnStyle='columns-0 md:columns-2 lg:columns-4' />}
                    {value == 'articles' && <Content/>}
                </div>
            
            </div>
        </div>
    );
}


export default Explore;
