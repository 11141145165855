import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchDataWithReferences, fetchSingleData } from '../api/api'; // Adjust the path as necessary
import { Heading } from '../Typography/Typography';
import BookCard from '../Components/BookCard';
import WallpaperGenerator from '../Components/WallpaperGenerator';



function ProfilePrimary() {

    const [record, setRecord] = useState({});
    const { writerId } = useParams();


    useEffect(() => {
        async function getData() {
            const data = await fetchSingleData('writers', writerId);
            if (data) {
                setRecord(data);
                console.log(data);
            }
        }
        getData();
    }, [writerId]);



    const {
        name,
        bio,
        city,
        state,
        country,
        contact,
        email,
        gender,
        nicname
    } = record;

    return (
        <div className='w-full bg-orange-000 flex justify-center'>



                <div className='bg-white flex flex-col justify-center items-center w-[80%] h-auto font-serif p-10 space-y-10'   >

                   
                   
                    <div className='items-center space-y-4 text-center'>
                        <div className='h-auto w-[15vw] aspect-square rounded-full bg-gray-200'>
                        </div>
                        <p className='text-4xl font-semibold'>{name}</p>
                    </div>


                    <div className='flex space-x-5'>
                        <p> <span className='font-semibold'>{city.toUpperCase()}</span>,</p>
                        <p> <span className='font-semibold'>{state.toUpperCase()}</span>,</p>
                        <p> <span className='font-semibold'>{country.toUpperCase()}</span></p>
                    </div>


                    <SectionBox>
                        <Heading1 text='About' />
                        <p className='mx-20 text-center'>{bio}</p>
                    </SectionBox>

                    <SectionBox>
                        <Heading1 text='Education' />
                        <EducationCard course='BCA' institute='Modicollege, Patiala' duration='2015-2018' />
                    </SectionBox>

                    <SectionBox>
                        <Heading1 text='Work' />
                        <WorkCard designation='Graphics Designer' company='Akai india' duration='2015-2018' />
                    </SectionBox>

                    <SectionBox>
                        <Heading1 text='Achievement' />
                        <AchievementCard main='Won Gold' secondry='Competation clay modeling' validation='CUIJH156859' />
                    </SectionBox>

                    <SectionBox>
                        <BooksSection/>

                    </SectionBox>


                    {/* <WallpaperGenerator/> */}


                    <SectionBox>
                        <ArticleCard />
                    </SectionBox>

                </div>
        </div>
    );
}








function Heading1(props) {

    return (
        <div>
            <p className='text-3xl  text-orange-700'>{props.text}</p>
        </div>
    )

}


function ArticleCard(props) {

    return (
        <div className='w-[50vw] h-[30vh] shrink-0 flex bg-slate-100 shadow-xl rounded-xl border'>
            <p className='text-2xl font-semibold text-orange-700'>{props.text}</p>
        </div>
    )

}


function SectionBox({ children, ...props }) {
    return (
        <div className='flex flex-col items-center space-y-4 my-5' {...props}>
            {children}
        </div>
    );
}


function EducationCard(props) {

    return (
        <div className='text-center  text-slate-700'>
            <p className='text-2xl font-semibold'>{props.course}</p>
            <p className='text-xl'>{props.institute}</p>
            <p className='text-md'>{props.duration}</p>

        </div>
    )

}


function WorkCard(props) {

    return (
        <div className='text-center  text-slate-700'>
            <p className='text-2xl font-semibold'>{props.designation}</p>
            <p className='text-xl'>{props.company}</p>
            <p className='text-md'>{props.duration}</p>

        </div>
    )
}



function AchievementCard(props) {

    return (
        <div className='text-center  text-slate-700'>
            <p className='text-2xl font-semibold'>{props.main}</p>
            <p className='text-xl'>{props.secondry}</p>
            <p className='text-md'>{props.validation}</p>

        </div>
    )
}






function BooksSection() {
  
    const [record, setRecord] = useState([]);
  
    useEffect(() => {
      async function getData() {
        const data = await fetchDataWithReferences('books', ['writers(name)', 'publishers(name)']);
        if (data) {
          setRecord(data);
          console.log(data);
        }
      }
      getData();
    }, []);
  
  
    return (
      <div className='flex items-center w-full justify-center my-20'>
        <div className='w-[95vw] md:w-[80vw] h-full bg-red-000'>
          <div className='h-[12vh] bg-slate-000 col-span-3'>
            <Heading text='Books' />
          </div>
          <div className='h-full w-full p-8 overflow-x-scroll bg-slate-000 flex justify-center space-x-6'>
           
            {record.map((book, index) => (
            
              <BookCard key={index} data={book} />
            
            ))}
  
          </div>
        </div>
      </div>
    );
  }






export default ProfilePrimary;
