import { useEffect, useState } from 'react';
import { Heading } from '../Typography/Typography';
import { fetchDataWithReferences, fetchDataWithFilter } from '../api/api'; // Adjust the path as necessary
import WriterCard from '../Components/WriterCard';
import PublisherCard from '../Components/PublisherCard';



function Publishers() {


  const sect = ['emarging', 'eminent'];

  const [publisher, setPublisher] = useState([]);

  useEffect(() => {
    async function getAllPublisher() {
      const data = await fetchDataWithReferences('publisher', 'books, location');
      if (data) {
        setPublisher(data);
        console.log('Fetched all writers:', data);
      }
    }
    getAllPublisher();
  }, []);




  return (
    <div>
      <div className='w-full h-[92vh] bg-zinc-900 grid grid-cols-5'>
        <div className='bg-gray-200 rounded-xl m-3'>

        </div>
        <div className='col-span-4 overflow-scroll h-[92vh]'>
          <PublisherSection header='Eminent' writers={publisher} />
        </div>
      </div>
    </div>
  );
}




function PublisherSection({ header, writers }) {
  return (
    <div className='flex items-center justify-center '>
      <div className='w-[95vw] md:w-[80vw] h-full bg-white overflow-auto rounded-xl my-3 mx-3'>
        <div className='h-[12vh] bg-slate-000 col-span-3'>
          <Heading text={header} />
        </div>
        <div className='h-full p-8 bg-slate-000 flex overflow-auto space-x-6'>
          {writers.map((writer, index) => (<PublisherCard key={index} data={writer} />))}
        </div>
      </div>
    </div>
  );
}


export default Publishers;
