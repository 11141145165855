import React, { useEffect, useState } from 'react';
import PocketBase from 'pocketbase';
import { FaStar } from 'react-icons/fa';
import OverlayWindow from './OverlayWindow';
import { fetchDataWithFilter } from '../api/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const pb = new PocketBase(process.env.REACT_APP_PKT_URL);

const AddReview = ({ book_id, user_id, data1 }) => {
    const [rating, setRating] = useState(0);
    const [text, setText] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [records, setRecords] = useState([]);

    console.log('review', user_id, book_id);


    const writerId = localStorage.getItem('writerId');
    const writerName = localStorage.getItem('writerName');

    const writer2Id = data1.expand && data1.expand.writer_id && data1.expand.writer_id.id


    useEffect(() => {
        async function getReviews() {
            try {
                const data = await fetchDataWithFilter('review', 'user_id, writer_id, book_id');
                if (data) {
                    setRecords(data);
                }
            } catch (error) {
                console.error('Error fetching reviews:', error);
            }
        }
        getReviews();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(false);

        if (rating < 1 || rating > 5) {
            setError('Rating must be between 1 and 5.');
            return;
        }

        try {
            const data = await pb.collection('review').create({
                book_id,
                user_id,
                rating,
                text,
            });

            await pb.collection('books').update(book_id, { 'review+': [data.id] });

            const notId = await pb.collection('notification').create({from:writerId, name:writerName,  toUser_id:user_id, text:'added a review', collection_id:'books', item_id:[data.id]});
            const postRecord2 = await pb.collection('writer').update(writer2Id, { 'notification+': [notId.id] });





            setSuccess(true);
            setRating(0);
            setText('');
            toast("Review Added",{autoClose:3000, theme:'light', hideProgressBar:true, type:'success'});
        } catch (err) {
            setError('Failed to submit review. Please try again.');
        }
    };

    return (
        <div className="w-full flex flex-col justify-center items-center">
            
            
            <div className='w-[60%] flex justify-between items-center'>
                
            <OverlayWindow btnName='Write a Review' btnStyle='p-3 rounded-full border border-zinc-500 Hover:border-orange-500'>
                <div className="w-[50vw] h-[50vh] mx-auto p-4 bg-white shadow-lg rounded-lg">
                    <h3 className="text-lg font-semibold mb-4">Add Your Review</h3>
                    {error && <p className="text-red-500 mb-2">{error}</p>}
                    {success && <p className="text-green-500 mb-2">Review submitted successfully!</p>}
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="rating" className="block text-sm font-medium text-gray-700">
                                Rating:
                            </label>
                            <div className="flex items-center">
                                {[...Array(5)].map((_, index) => {
                                    const currentRating = index + 1;
                                    return (
                                        <button
                                            key={index}
                                            type="button"
                                            onClick={() => setRating(currentRating)}
                                            className={`text-2xl ${currentRating <= rating ? 'text-yellow-500' : 'text-gray-300'}`}
                                        >
                                            <FaStar />
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="text" className="block text-sm font-medium text-gray-700">
                                Comment:
                            </label>
                            <textarea
                                id="text"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                required
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            className="w-max bg-zinc-700 text-white p-2 rounded-md hover:bg-zinc-900 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        >
                            Submit Review
                        </button>
                    </form>
                    <ToastContainer />
                </div>
            </OverlayWindow>
            <p className="text-xl">{records && records.length} Reviews</p>

            </div>


            <div className="w-full h-max m-0 flex flex-col items-center justify-center">
                {records.map((review, index) => (
                    <ReviewCard key={index} review={review} />
                ))}
            </div>
        </div>
    );
};

function ReviewCard({ review }) {
    return (
        <div className="my-5 bg-zinc-100 p-3 w-[60%] rounded-xl border border-zinc-300">
            <p>Review by {review.expand && review.expand.writer_id && review.expand.writer_id.name}</p>
            <div className="flex items-center">
                {[...Array(5)].map((_, index) => (
                    <FaStar
                        key={index}
                        className={`text-md my-3 ${index < review.rating ? 'text-yellow-500' : 'text-gray-300'}`}
                    />
                ))}
            </div>
            <p>{review.text}</p>
        </div>
    );
}

export default AddReview;
