import { Link } from 'react-router-dom';
import ImageView from './ImageView';
import FollowButton from './FollowButton';


function PublisherCard({ data }) {
   
    const { name, id, collectionId, profile_image, about, expand = {}, age, gender } = data;

    const locations = expand.location && expand.location;
   
    const imageUrl = `${process.env.REACT_APP_PKT_URL}/${collectionId}/${id}/${profile_image}`;

    console.log(locations);
    console.log('publisher',data);


    return (
        <div className='w-[220px] h-[60%] p-1 font bg-slate-000 shrink-0 flex flex-col justify-center items-center space-y-2 overflow-hidden'>
            
            <Link to={`/publisher/${id}/${name.replace(/\s+/g, '-')}`}>
            <ImageView collectionId={collectionId} id={id} image={profile_image}/>
            <p className='text-md font-semibold text-center'>{name}</p>
            </Link>
            { expand.location &&  <p className='text-sm'>{locations[0].city}, {locations[0].state}, {locations[0].country}</p>}
            <FollowButton data={data}/>
        </div>
    );
}



export default PublisherCard;
