import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import { useNavigate, useParams } from 'react-router-dom';
import Location from '../Components/Location';
import Education from '../Components/Education';

import ImageUpload from '../Components/ImageUpload';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);


const UserProfileEdit = () => {

    const { userId } = useParams();

    const navigate = useNavigate();

    const [profile, setProfile] = useState({
        name: '',
        about: '',
        gender: '',
        age: 0,
        dob: null,
        type: '',
    });



    


    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const record = await pb.collection('writer').getOne(userId);
                setProfile(record);
                console.log(record)
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchProfile();
    }, [userId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value
        }));
    };




    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await pb.collection('writer').update(userId, profile);
            // navigate(`/profile/${userId}`);
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };



    return (
        <div className='h-[92vh] w-full bg-zinc-900'>
        <div className="grid grid-cols-1 p-10  justify-center w-[50vw] mx-auto overflow-scroll h-[92vh]">
            {/* <h1 className="text-2xl font-bold mb-4">Edit Profile 111</h1> */}
            <div className='col-span-1'>
                
                
            <ImageUpload collection={profile.collectionId} id={profile.id} imageField='profile_image' />

                <form onSubmit={handleSubmit} className="space-y-4 bg-white p-10 my-8 rounded-2xl overflow-hidden">
                    

                    <div>
                        <label className=" text-sm font-medium">Name</label>
                        <input
                            type="text"
                            name="name"
                            value={profile.name}
                            onChange={handleChange}
                            className="mt-1 w-full border rounded p-2"
                        />
                    </div>

                    <div>
                        <label className=" text-sm font-medium">About</label>
                        <textarea
                            name="about"
                            value={profile.about}
                            onChange={handleChange}
                            className="mt-1 w-full border rounded p-2 h-[25vh]"
                        />
                    </div>

                    <div className='space-x-3'>
                        <label htmlFor='gender' className=" text-sm font-medium">Gender</label>

                        <select
                            id="gender"
                            name="gender"
                            value={profile.gender}
                            onChange={handleChange}
                            className='p-3 border rounded-full text-sm'
                            required
                        >
                            <option value="">Select</option>
                            <option value="female">Female</option>
                            <option value="male">Male</option>
                            <option value="other">Other</option>
                        </select>
                    </div>

                    <div>
                        <label className=" text-sm font-medium">DOB</label>
                        <input
                            type="date"
                            name="dob"
                            value={profile.dob}
                            onChange={handleChange}
                            className="mt-1 w-full border rounded p-2"
                        />
                    </div>


                    <button type="submit" className="bg-zinc-700 text-white p-2 rounded-full px-5 mt-4">
                        Save
                    </button>


                </form>
            </div>

            <div className='col-span-1 bg-zinc-000 h-full'>

                <div className='my-8 bg-white rounded-2xl'>
                    <Location writerId={userId} />
                </div>

                <div className='my-8 bg-white rounded-2xl'>
                    <Education writerId={userId} />
                </div>


            </div>

        </div>
        </div>
    );
};

export default UserProfileEdit;
